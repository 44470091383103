import React from "react";

import {
  Squares2X2Icon,
  ChartBarIcon,
  CursorArrowRaysIcon,
  CommandLineIcon,
  ShoppingCartIcon,
  UserGroupIcon,
} from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";

const features = [
  {
    id: 1,
    title: "Data and reports",
    description:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet.",
    icon: ChartBarIcon,
    href: "",
  },
  {
    id: 2,
    title: "Multichannel",
    description:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet.",
    icon: Squares2X2Icon,
    href: "",
  },
  {
    id: 3,
    title: "Automations",
    description:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet.",
    icon: CursorArrowRaysIcon,
    href: "",
  },
  {
    id: 4,
    title: "Integration POS",
    description:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet.",
    icon: CommandLineIcon,
    href: "",
  },
  {
    id: 5,
    title: "E-commerce",
    description:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet.",
    icon: ShoppingCartIcon,
    href: "",
  },
  {
    id: 6,
    title: "Loyalty",
    description:
      "Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet.",
    icon: UserGroupIcon,
    href: "",
  },
];

const Features = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <section className="mt-12">
        <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
          <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-[#ec392a] uppercase rounded-full bg-teal-accent-400">
                {t("whyclauuppercase")}
              </p>
            </div>
            <h2 className="max-w-2xl mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-[#2E307C] sm:text-4xl md:mx-auto">
              <span className="relative inline-block">
                <svg
                  viewBox="0 0 52 24"
                  fill="currentColor"
                  className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                >
                  <defs>
                    <pattern
                      id="18302e52-9e2a-4c8e-9550-0cbb21b38e55"
                      x="0"
                      y="0"
                      width=".135"
                      height=".30"
                    >
                      <circle cx="1" cy="1" r=".7" />
                    </pattern>
                  </defs>
                  <rect
                    fill="url(#18302e52-9e2a-4c8e-9550-0cbb21b38e55)"
                    width="52"
                    height="24"
                  />
                </svg>
                <span className="relative">{t("whyclautitlespan")}</span>
              </span>{" "}
              {t("whyclautitle")}
            </h2>
            <p className="text-base text-gray-700 md:text-lg">
              {t("whyclaudescription")}
            </p>
          </div>
          <div className="grid gap-12 row-gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {i18n.t("featuresClau", { returnObjects: true }).map((feature) => (
              <div className="group cursor-pointer">
                <div className="flex flex-col justify-between p-8 border bg-[#fff] rounded-[23px] shadow-sm  hover:scale-105 group-hover:transition">
                  <div className="h-[180px]">
                    <div className="flex items-center justify-center w-16 h-16 mb-4 rounded-full bg-[#EC392A] group-hover:bg-[#2E307C]">
                      <img
                        className="h-10 w-10  p-1 text-white group-hover:text-[#ec392a]"
                        aria-hidden="true"
                        src={feature.icon}
                      />
                    </div>
                    <div className="text-left mb-2">
                      <h6 className="mb-2 mt-2 text-[20px] text-[#2E307C] font-semibold leading-5 ">
                        {feature.title}
                      </h6>
                      <p className="mt-5 text-sm text-gray-500 ">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Features;
