import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Modal from "../components/Modal";
import { useTranslation } from "react-i18next";
import Slider from "react-slick";
import Features from "../components/Features";
import Contact from "../components/Contact";

const Restaurants = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 20000,
    slidesToShow: 6,
    slidesToScroll: 4,
    initialSlide: 0,
    cssEase: "linear",
    className: "wrapper",
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,

          // dots: true,
        },
      },
      {
        breakpoint: 812,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {isOpen && <Modal setIsOpen={setIsOpen} />}

      <div className="">
        <div className="bg-[#FFE6DE]">
          <div className="px-4 relative mx-auto sm:max-w-xl md:max-w-full  lg:px-8 ">
            <div className="px-4  text-left  mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 content">
              <div className="grid gap-12 row-gap-8 lg:grid-cols-2">
                <div className="flex flex-col justify-center">
                  <div className="max-w-xl mb-6 mt-16 lg:mt-0 md:mt-24">
                    <p className="mb-5 text-xs font-semibold tracking-widest text-[#2E307C] uppercase title-font text-pirGray-100">
                      &mdash; {t("foodorderuppercase")}
                    </p>
                    <h1 class="text-4xl font-semibold sm:text-6xl space-y-3 text-[#2E307C]">
                      {t("foodordertitle")}
                    </h1>
                    <p class="mt-5 text-gray-600 leading-loose max-w-md">
                      {t("foodorderdescription")}
                    </p>
                  </div>
                  <div className="flex space-x-4 mt-4">
                    <button
                      class=" inline-flex items-center px-8 py-3.5 overflow-hidden text-white bg-[#2E307C] rounded-full group active:bg-indigo-500 focus:outline-none focus:ring hover:bg-[#363996]"
                      onClick={() => {
                        setIsOpen(true);
                      }}
                    >
                      <span class=" font-medium ">{t("foodorderbutton")}</span>
                    </button>
                  </div>
                </div>

                <Carousel
                  className="z-10 relative ss"
                  autoPlay={true}
                  showThumbs={false}
                  showArrows={false}
                  infiniteLoop={true}
                  interval={6000}
                  swipeable={false}
                  showStatus={false}
                  showIndicators={false}
                  swipeAnimationHandler={false}
                  animationHandler="fade"
                  handleTransition="fade"
                >
                  <div>
                    <img
                      className="self-end"
                      src="https://img.invupos.com/clauweb/Restaurants%20module/restaurants%20photo%201.png"
                      loading="lazy"
                      alt="Food Order Hero"
                    />
                  </div>
                  <div>
                    <img
                      className="self-end"
                      src="https://img.invupos.com/clauweb/Restaurants%20module/Restaurants%20photo%202.png"
                      loading="lazy"
                      alt="Food Order Hero"
                    />
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
        <div className="relative px-4 sm:px-0 ">
          <div className="absolute inset-0 bg-[#FFE6DE] h-1/2 " />
          <div className="relative grid mx-auto overflow-hidden  bg-white divide-y rounded-full shadow sm:divide-y-0 sm:divide-x sm:max-w-screen-sm  lg:max-w-screen-xl">
            <div className="wrapper p-6 mt-3 space-x-10">
              <Slider {...settings}>
                <div className="mt-2">
                  <img
                    class="h-9 sm:h-10 md:h-9 mx-auto lg:h-10"
                    src="https://img.invupos.com/clauweb/Partners/cinnabon1.svg"
                    alt="Cinnabon"
                    loading="lazy"
                  />
                </div>
                <div className="mt-1 lg:ml-4 sm:ml-2 md:ml-2 ml-2">
                  <img
                    class="h-9 sm:h-10 md:h-10 mx-auto lg:h-11 "
                    src="https://img.invupos.com/clauweb/Partners/Sushiexpress1.svg"
                    alt="Sushi Express"
                    loading="lazy"
                  />
                </div>
                <div className="mt-2.5 lg:ml-6 sm:ml-6 md:ml-6 ml-6">
                  <img
                    class="h-7 sm:h-8 md:h-9 mx-auto lg:h-[35px]"
                    src="https://img.invupos.com/clauweb/Partners/Elcosto1.png"
                    alt="El Costo"
                    loading="lazy"
                  />
                </div>
                <div className="mt-2 sm:ml-6 md:ml-5 lg:ml-6 ml-3">
                  <img
                    class="h-7 sm:h-8 md:h-9 mx-auto lg:h-9 ml-9"
                    src="https://img.invupos.com/clauweb/Partners/Grupotovasvg.svg"
                    alt="Grupo Tova"
                    loading="lazy"
                  />
                </div>
                <div className="mt-1.5 lg:ml-4 sm:ml-4 md:ml-4 ml-3">
                  <img
                    class="h-9 sm:h-10 md:h-10 mx-auto lg:h-[42px]"
                    src="https://img.invupos.com/clauweb/Partners/CredicorpBank.png"
                    alt="Credicorp Bank"
                    loading="lazy"
                  />
                </div>
                <div className="mt-1.5 lg:ml-7 sm:ml-8 md:ml-8 ml-8">
                  <img
                    class="h-10 sm:h-10 md:h-11 mx-auto lg:h-12"
                    src="https://img.invupos.com/clauweb/Partners/Tsugoi.webp"
                    alt="Tsugoi"
                    loading="lazy"
                  />
                </div>
                <div className="mt-.5 lg:ml-6 sm:ml-6 md:ml-6 ml-6">
                  <img
                    class="h-12 sm:h-[53px] md:h-[53px] mx-auto lg:h-[58px]"
                    src="https://img.invupos.com/clauweb/Partners/FD%20Center.jpeg"
                    alt="FDCenter"
                    loading="lazy"
                  />
                </div>
                <div className="mt-2.5 lg:ml-2 sm:ml-2 md:ml-2 ml-2">
                  <img
                    class="h-7 sm:h-9 md:h-9 mx-auto lg:h-[38px]"
                    src="https://img.invupos.com/clauweb/Partners/Bravapizza.png"
                    alt="Brava Pizza"
                    loading="lazy"
                  />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
      {isOpen && <Modal setIsOpen={setIsOpen} />}

      <section>
        <div class="px-4 py-28 mx-auto max-w-screen-xl sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 gap-8 lg:gap-16 lg:grid-cols-2">
            <img
              alt="#"
              src="https://img.invupos.com/clauweb/Sections%20images%20/Sections1.webp"
              class="object-cover w-full h-full sm:h-full"
            />
            <div class="lg:py-28 text-left">
              <p className="inline-block  py-px mb-4 text-xs font-semibold tracking-wider text-[#EC392A] uppercase rounded-full bg-teal-accent-400">
                &mdash; {t("ecommerceuppercase")}
              </p>
              <h2 class="text-3xl font-bold  sm:text-5xl text-[#2E307C]">
                {t("ecommercetitle")}
              </h2>

              <p class="mt-4 text-gray-600">{t("ecommercedescription")}</p>

              <div class="flex space-x-4 mt-6">
                <button
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  class="w-full py-3 px-6 text-center  rounded-full transition duration-300 bg-[#EC392A]  active:bg-cyan-600 focus:bg-cyan-800 sm:w-max hover:bg-[#DE3E31]"
                >
                  <span class="block text-white text-sm font-semibold">
                    {t("ecommercebuttonone")}
                  </span>
                </button>
                {/* <button
                  type="button"
                  title="Start buying"
                  class="w-full py-3 px-6 text-center rounded-full transition border border-[#EC392A] sm:w-max hover:border-[#DE3E31]"
                >
                  <span class="block font-semibold text-[#EC392A] text-sm">
                    {t("ecommercebuttontwo")}
                  </span>
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-[#F3F4F6]">
        <div class="px-4 py-28 mx-auto max-w-screen-xl sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 gap-8 lg:gap-16 lg:grid-cols-2 ">
            <div className="relative  overflow-hidden  lg:order-last">
              <img
                alt="#"
                src="https://img.invupos.com/clauweb/Sections%20images%20/Loyaltysection.webp"
                class="object-cover  w-full h-full sm:h-full"
              />
            </div>
            <div class="lg:py-28 text-left">
              <p className="inline-block  py-px mb-4 text-xs font-semibold tracking-wider text-[#EC392A] uppercase rounded-full bg-teal-accent-400">
                &mdash; {t("loyaltyuppercase")}
              </p>
              <h2 class="text-3xl font-bold  sm:text-5xl text-[#2E307C]">
                {t("loyaltytitle")}
              </h2>

              <p class="mt-4 text-gray-600">{t("loyaltydescription")}</p>

              <div class="flex space-x-4 mt-6">
                <button
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  class="w-max py-3 px-6 text-center  rounded-full transition duration-300 bg-[#EC392A]  active:bg-cyan-600 focus:bg-cyan-800 hover:bg-[#DE3E31]"
                >
                  <span class="block text-white text-sm font-semibold">
                    {t("loyaltybuttonone")}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Features />
      <Contact />
    </>
  );
};

export default Restaurants;
