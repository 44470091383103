import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Modal from "../components/Modal";
import { useTranslation } from "react-i18next";

const FoodOrder = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <div className="">
        <div className="bg-[#FFE6DE]">
          <div className="px-4 relative mx-auto sm:max-w-xl md:max-w-full  lg:px-8 ">
            <div className="px-4  text-left  mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 content">
              <div className="grid gap-12 row-gap-8 lg:grid-cols-2">
                <div className="flex flex-col justify-center">
                  <div className="max-w-xl mb-6 mt-16 lg:mt-0 md:mt-24">
                    <p className="mb-5 text-xs font-semibold tracking-widest text-[#2E307C] uppercase title-font text-pirGray-100">
                      &mdash; {t("foodorderuppercase")}
                    </p>
                    <h1 class="text-4xl font-semibold sm:text-6xl space-y-3 text-[#2E307C]">
                      {t("foodordertitle")}
                    </h1>
                    <p class="mt-5 text-gray-600 leading-loose max-w-md">
                      {t("foodorderdescription")}
                    </p>
                  </div>
                  <div className="flex space-x-4 mt-4">
                    <button
                      class=" inline-flex items-center px-8 py-3.5 overflow-hidden text-white bg-[#2E307C] rounded-full group active:bg-indigo-500 focus:outline-none focus:ring hover:bg-[#363996]"
                      href="/"
                      onClick={() => {
                        setIsOpen(true);
                      }}
                    >
                      <span class=" font-medium ">{t("foodorderbutton")}</span>
                    </button>
                  </div>
                </div>

                <Carousel
                  className="z-10 relative ss"
                  autoPlay={true}
                  showThumbs={false}
                  showArrows={false}
                  infiniteLoop={true}
                  interval={6000}
                  swipeable={false}
                  showStatus={false}
                  showIndicators={false}
                  swipeAnimationHandler={false}
                  animationHandler="fade"
                  handleTransition="fade"
                >
                  <div>
                    <img
                      className="self-end"
                      src="https://img.invupos.com/clauweb/Food%20Order/Food_order_photo_1.webp"
                      loading="lazy"
                      alt="Food Order Hero"
                    />
                  </div>
                  <div>
                    <img
                      className="self-end"
                      src="https://img.invupos.com/clauweb/Food%20Order/Food_order_photo_2.webp"
                      loading="lazy"
                      alt="Food Order Hero"
                    />
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpen && <Modal setIsOpen={setIsOpen} />}
    </>
  );
};

export default FoodOrder;
