// import i18n from "i18next";
// import { initReactI18next } from "react-i18next";
// import esTranslation from "./i18n/es.json";
// import enTranslation from "./i18n/en.json";

// const resources = {
//   es: {
//     translation: esTranslation,
//   },
//   en: {
//     translation: enTranslation,
//   },
// };

// i18n.use(initReactI18next).init({
//   resources,
//   lng: "es",
// });

// export default i18n;

import i18n from "i18next";
import enTranslation from "../src/i18n/en.json";
import esTranslation from "../src/i18n/es.json";

import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import I18NextHttpBackend from "i18next-http-backend";
import Backend from "i18next-http-backend";
import XHR from "i18next-http-backend";
import detector from "i18next-browser-languagedetector";

i18n.on("languageChanged", function (lng) {
  if (lng === i18n.options.fallbackLng[1]) {
    if (window.location.pathname.includes("/" + i18n.options.fallbackLng[1])) {
      const newUrl = window.location.pathname.replace(
        "/" + i18n.options.fallbackLng[1],
        ""
      );
      window.location.replace(newUrl);
    }
  }
});

const DETECTION_OPTIONS = {
  order: ["localStorage", "navigator"],
  caches: ["localStorage"],
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(XHR)
  .use(detector)
  .use(I18NextHttpBackend)
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      es: {
        translation: esTranslation,
      },
    },
    whitelist: ["es", "en"],
    fallbackLng: "es",

    detection: {
      order: ["path"],
      detection: DETECTION_OPTIONS,
      lookupFromPathIndex: 0,
      checkWhitelist: true,
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: ".",
    },
    react: {
      wait: true,
    },

    debug: true,
    ns: ["translation"],
    defaultNS: "translation",

    keySeparator: false,
  });

export default i18n;
