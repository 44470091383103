import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "./Modal";

const Pricing = () => {
  const { t, i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      {isOpen && <Modal setIsOpen={setIsOpen} />}
      <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
        <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
          <div>
            <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-[#EC392A] uppercase rounded-full bg-teal-accent-400">
              {t("claupricinguppercase")}
            </p>
          </div>
          <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-[#2E307C] sm:text-4xl md:mx-auto">
            <span className="relative inline-block">
              <svg
                viewBox="0 0 52 24"
                fill="currentColor"
                className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
              >
                <defs>
                  <pattern
                    id="7e5e8ff8-1960-4094-a63a-2a0c0f922d69"
                    x="0"
                    y="0"
                    width=".135"
                    height=".30"
                  >
                    <circle cx="1" cy="1" r=".7" />
                  </pattern>
                </defs>
                <rect
                  fill="url(#7e5e8ff8-1960-4094-a63a-2a0c0f922d69)"
                  width="52"
                  height="24"
                />
              </svg>
              <span className="relative">{t("claupricingtitlespan")}</span>
            </span>{" "}
            {t("claupricingtitle")}
          </h2>
          <p className="text-base mb-20 text-gray-500 md:text-lg ">
            {t("claupricingdescription")}
          </p>
        </div>

        <div className="grid max-w-md gap-12  row-gap-5 lg:max-w-screen-lg sm:row-gap-10 lg:grid-cols-3 xl:max-w-screen-lg sm:mx-auto">
          <div className="flex flex-col justify-between p-8 transition-shadow duration-300 bg-white border rounded-[25px] shadow-sm sm:items-left hover:shadow">
            <div className="text-left">
              <div class="flex items-start">
                <span class="flex-shrink-0 rounded-[16px] rounded-lg">
                  <img
                    class="w-14 h-14 rounded-[16px]"
                    alt="pricing icon"
                    src="https://img.invupos.com/clauweb/Pricing/Icon_pricing_1_1_c1uvct.svg"
                  />
                </span>

                <div class="ml-4">
                  <p class="mt-1 text-sm text-gray-400">
                    {t("pricingcardonespantitle")}
                  </p>
                  <h2 class="text-lg font-bold text-[#170F49]">
                    {t("pricingcardoneplan")}
                  </h2>
                </div>
              </div>
              <p class="mt-5 text-sm text-gray-400">
                {t("pricingcardonedescription")}{" "}
              </p>

              <p className="text-center px-3 py-px mt-6 text-[11px] font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                {t("pricingcardonespan")}
              </p>
              <div className="flex items-center justify-center mt-2">
                <div className="mr-1 text-5xl font-bold text-[#170F49]">
                  $49
                </div>
                <div className="text-gray-700">/ {t("pricingtext")}</div>
              </div>
              <p className="text-center px-3 py-px mt-1 text-[11px]  tracking-wider text-gray-500  rounded-full bg-teal-accent-400">
                {t("pricingcommision")}
              </p>
              <div className="mt-5">
                <p className="mb-3 font-bold tracking-wide text-[#170F49]">
                  {t("pricingwhatsincluded")}
                </p>
                <ul className="space-y-3">
                  <li className="flex items-center">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        viewBox="0 0 20 20"
                        fill="#EC392A"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="font-medium text-[#170F49]">
                      {t("pricingcardoneitem1")}
                    </p>
                  </li>
                  <li className="flex items-center">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        viewBox="0 0 20 20"
                        fill="#EC392A"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="font-medium text-[#170F49]">
                      {t("pricingcardoneitem2")}
                    </p>
                  </li>
                  <li className="flex items-center">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        viewBox="0 0 20 20"
                        fill="#EC392A"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="font-medium text-[#170F49]">
                      {t("pricingcardoneitem3")}
                    </p>
                  </li>
                  <li className="flex items-center">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        viewBox="0 0 20 20"
                        fill="#EC392A"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="font-medium text-[#170F49]">
                      {t("pricingcardoneitem4")}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <button
                onClick={() => {
                  setIsOpen(true);
                }}
                className="inline-flex items-center justify-center w-full h-12 px-6 mt-6 font-medium tracking-wide text-white transition duration-200 bg-[#EC392A] hover:bg-[#DE3E31] rounded-full shadow-md  focus:shadow-outline focus:outline-none"
              >
                {t("pricingbuttoncard")}
              </button>
              <p className="max-w-xs mt-3 text-xs text-gray-600 sm:text-xs sm:text-center sm:max-w-sm sm:mx-auto">
                {t("pricingspan")}
              </p>
            </div>
          </div>
          {/* Pricing 2 */}
          <div className="relative flex flex-col justify-between p-8 transition-shadow duration-300 bg-[#EC392A] border rounded-[25px] shadow-sm sm:items-left hover:shadow">
            <div className="text-left">
              <div class="flex items-start">
                <span class="flex-shrink-0 rounded-[16px] rounded-lg">
                  <img
                    class="w-14 h-14 rounded-[16px]"
                    alt="pricing icon"
                    src="https://img.invupos.com/clauweb/Pricing/Pricing_icon_2_o2t58c.svg"
                  />
                </span>

                <div class="ml-4">
                  <p class="mt-1 text-sm text-[#EFF0F6]">
                    {t("pricingcardtwospantitle")}
                  </p>
                  <h2 class="text-lg font-bold text-[#fff]">
                    {t("pricingcardtwoplan")}
                  </h2>
                </div>
              </div>
              <p class="mt-5 text-sm text-[#EFF0F6]">
                {t("pricingcardtwodescription")}{" "}
              </p>
              <p className="text-center px-3 py-px mt-6 text-[11px] font-semibold tracking-wider text-white uppercase rounded-full bg-teal-accent-400">
                {t("pricingcardonespan")}
              </p>
              <div className="flex items-center justify-center mt-2">
                <div className="mr-1 text-5xl font-bold text-[#fff]">$49</div>
                <div className="text-[#EFF0F6]">/ {t("pricingtext")}</div>
              </div>
              <div className="mt-5">
                <p className="mb-3 font-bold tracking-wide text-[#fff]">
                  {t("pricingwhatsincluded")}
                </p>
                <ul className="space-y-3">
                  <li className="flex items-center">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        viewBox="0 0 20 20"
                        fill="#fff"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="font-medium text-[#fff]">
                      {t("pricingcardtwoitem1")}
                    </p>
                  </li>
                  <li className="flex items-center">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        viewBox="0 0 20 20"
                        fill="#fff"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="font-medium text-[#fff]">
                      {t("pricingcardtwoitem2")}
                    </p>
                  </li>
                  <li className="flex items-center">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        viewBox="0 0 20 20"
                        fill="#fff"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="font-medium text-[#fff]">
                      {t("pricingcardtwoitem3")}
                    </p>
                  </li>
                  <li className="flex items-center">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        viewBox="0 0 20 20"
                        fill="#fff"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="font-medium text-[#fff]">
                      {t("pricingcardtwoitem4")}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <button
                onClick={() => {
                  setIsOpen(true);
                }}
                className="inline-flex items-center justify-center w-full h-12 px-6 mt-6 font-medium tracking-wide text-[#EC3B2C] hover:bg-[#F5F5F5] transition duration-200 bg-[#fff] rounded-full shadow-md focus:shadow-outline focus:outline-none"
              >
                {t("pricingbuttoncard")}
              </button>
              <p className="max-w-xs mt-3 text-xs text-[#fff] sm:text-xs sm:text-center sm:max-w-sm sm:mx-auto">
                {t("pricingspan")}
              </p>
            </div>
          </div>
          {/* Pricing 3 */}
          <div className="flex flex-col justify-between p-8 transition-shadow duration-300 bg-white border rounded-[25px] shadow-sm sm:items-left hover:shadow">
            <div className="text-left">
              <div class="flex items-start">
                <span class="flex-shrink-0 rounded-[16px] rounded-lg">
                  <img
                    class="w-14 h-14 rounded-[16px]"
                    alt="pricing icon"
                    src="https://img.invupos.com/clauweb/Pricing/Pricing_icon_3_fbokyo.svg"
                  />
                </span>

                <div class="ml-4">
                  <p class="mt-1 text-sm text-gray-400">
                    {t("pricingcardthreespantitle")}
                  </p>
                  <h2 class="text-lg font-bold text-[#170F49]">
                    {t("pricingcardthreeplan")}
                  </h2>
                </div>
              </div>
              <p class="mt-5 text-sm text-gray-400">
                {t("pricingcardthreedescription")}{" "}
              </p>
              <p className="text-center px-3 py-px mt-6 text-[11px] font-semibold tracking-wider text-teal-900 uppercase rounded-full bg-teal-accent-400">
                {t("pricingcardonespan")}
              </p>
              <div className="flex items-center justify-center mt-2 ">
                <div className="mr-1 text-5xl font-bold text-[#170F49]">
                  $29
                </div>
                <div className="text-gray-700">/ {t("pricingtext")}</div>
              </div>
              <div className="mt-5">
                <p className="mb-3 font-bold tracking-wide text-[#170F49]">
                  {t("pricingwhatsincluded")}
                </p>
                <ul className="space-y-3">
                  <li className="flex items-center">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        viewBox="0 0 20 20"
                        fill="#EC392A"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="font-medium text-[#170F49]">
                      {t("pricingcardthreeitem1")}
                    </p>
                  </li>
                  <li className="flex items-center">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        viewBox="0 0 20 20"
                        fill="#EC392A"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="font-medium text-[#170F49]">
                      {t("pricingcardthreeitem2")}
                    </p>
                  </li>
                  <li className="flex items-center">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        viewBox="0 0 20 20"
                        fill="#EC392A"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="font-medium text-[#170F49]">
                      {t("pricingcardthreeitem3")}
                    </p>
                  </li>
                  <li className="flex items-center">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-6 w-6"
                        viewBox="0 0 20 20"
                        fill="#EC392A"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="font-medium text-[#170F49]">
                      {t("pricingcardthreeitem4")}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <button
                onClick={() => {
                  setIsOpen(true);
                }}
                className="inline-flex items-center justify-center w-full h-12 px-6 mt-6 font-medium tracking-wide text-white transition duration-200 bg-[#EC392A] hover:bg-[#DE3E31]  rounded-full shadow-md focus:shadow-outline focus:outline-none"
              >
                {t("pricingbuttoncard")}
              </button>
              <p className="max-w-xs mt-3 text-xs text-gray-600 sm:text-xs sm:text-center sm:max-w-sm sm:mx-auto">
                {t("pricingspan")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
