import React, { useState } from "react";
import CountUp from "react-countup";

import ScrollTrigger from "react-scroll-trigger";
import { useTranslation } from "react-i18next";

const Results = () => {
  const [counterOn, setCounterOn] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <ScrollTrigger
        onEnter={() => setCounterOn(true)}
        // onExit={() => setCounterOn(true)}
      >
        <section className="bg-[#F7F7F9]">
          <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-32">
            <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
              <div>
                <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-[#EC392A] uppercase rounded-full bg-teal-accent-400">
                  {t("numbersuppercase")}
                </p>
              </div>
              <h2 className="max-w-2xl mb-20 font-sans text-3xl font-bold leading-none tracking-tight text-[#2E307C] sm:text-4xl md:mx-auto">
                {t("numberstitle")}
              </h2>
              {/* <p className="text-base mb-20 text-gray-500 md:text-lg ">
                Lorem ipsum dolor sit amet consectetur adipiscing elit dolor
                posuere vel venenatis eu sit massa volutpat.
              </p> */}
            </div>
            <div className="grid gap-8 row-gap-8 lg:grid-cols-4">
              <div className="sm:text-center">
                <h3 className="flex items-center justify-center  mb-4 text-5xl font-extrabold rounded-full text-[#ec392a]  sm:mx-auto">
                  {counterOn && <CountUp start={0} end={200} duration={5} />}
                </h3>
                <h6 className="mb-2 text-lg font-semibold text-center leading-5  text-[#2E307C]">
                  {t("numbertitle2")}
                </h6>
                <p className="max-w-md mb-3 text-sm text-gray-500 text-center sm:mx-auto">
                  {t("numberdescription2")}
                </p>
              </div>
              <div className="sm:text-center">
                <h3 className="flex items-center justify-center  mb-4 text-5xl font-extrabold rounded-full text-[#ec392a]  sm:mx-auto">
                  {counterOn && <CountUp start={0} end={25} duration={5} />}{" "}
                  <span>+</span>
                </h3>
                <h6 className="mb-2 text-lg font-semibold text-center leading-5  text-[#2E307C]">
                  {t("numbertitle3")}
                </h6>
                <p className="max-w-md mb-3 text-sm text-center text-gray-500 sm:mx-auto">
                  {t("numberdescription3")}
                </p>
              </div>
              <div className="sm:text-center">
                <h3 className="flex items-center justify-center  mb-4 text-5xl font-extrabold rounded-full text-[#ec392a]  sm:mx-auto">
                  {counterOn && <CountUp start={0} end={7} duration={5} />}{" "}
                  <span>x</span>
                </h3>
                <h6 className="mb-2 text-lg font-semibold leading-5 text-center text-[#2E307C]">
                  {t("numbertitle4")}
                </h6>
                <p className="max-w-md mb-3 text-sm text-gray-500 text-center sm:mx-auto">
                  {t("numberdescription4")}
                </p>
              </div>
              {/* <div className="sm:text-center">
                <h3 className="flex items-center justify-center  mb-4 text-5xl font-extrabold rounded-full text-[#ec392a]  sm:mx-auto">
                  {counterOn && <CountUp start={0} end={10} duration={5} />}{" "}
                  <span>x</span>
                </h3>
                <h6 className="mb-2 text-lg font-semibold leading-5 text-center text-[#2E307C]">
                  {t("numbertitle5")}
                </h6>
                <p className="max-w-md mb-3 text-sm text-gray-500 text-center sm:mx-auto">
                  {t("numberdescription5")}
                </p>
              </div> */}
              <div className="sm:text-center">
                <h3 className="flex items-center justify-center  mb-4 text-5xl font-extrabold rounded-full text-[#ec392a]  sm:mx-auto">
                  +{counterOn && <CountUp start={0} end={20} duration={5} />}{" "}
                  <span>%</span>
                </h3>

                <h6 className="mb-2 text-lg text-center font-semibold leading-5 text-[#2E307C]">
                  {t("numbertitle1")}
                </h6>
                <p className="max-w-md mb-3 text-sm text-center text-gray-500 sm:mx-auto">
                  {t("numberdescription1")}
                </p>
              </div>
            </div>
          </div>
        </section>
      </ScrollTrigger>
    </>
  );
};

export default Results;
