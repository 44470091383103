import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const Item = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border rounded-[15px]  shadow-sm group  block bg-white ring-1 ring-slate-900/5 shadow-lg space-y-3 hover:bg-white hover:ring-orange-600">
      <button
        type="button"
        aria-label="Open item"
        title="Open item"
        className="flex items-center justify-between w-full p-6 focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <p className="text-sm text-left lg:text-base max-w-[260px] lg:max-w-none md:max-w-none justify-start md:text-sm text-gray-700 group-hover:text-[#EC392A] font-semibold">
          {title}
        </p>
        <div className="flex items-center group-hover:bg-[#EC392A] justify-center w-8 h-8 border group-hover:border-[#EC392A] group-hover:transition rounded-full">
          <svg
            viewBox="0 0 24 24"
            className={`w-3 text-gray-600 group-hover:text-[#fff] transition-transform duration-200 ${
              isOpen ? "transform rotate-180" : ""
            }`}
          >
            <polyline
              fill="none"
              stroke="currentColor"
              strokeWidth="4"
              strokeLinecap="round"
              strokeMiterlimit="10"
              points="2,7 12,17 22,7"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </button>
      {isOpen && (
        <div className="p-4 pt-0">
          <p className="text-sm lg:text-base justify-start md:text-sm text-gray-700">
            {children}
          </p>
        </div>
      )}
    </div>
  );
};

const FAQ = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="bg-gray py-32">
        <div className=" max-w-1xl mx-auto sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8"></div>
        <div class="flex flex-wrap overflow-hidden max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <div className="max-w-xl  md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
            <div>
              <p className="inline-block px-3 py-px mb-4 text-xs font-semibold tracking-wider text-[#EC392A] uppercase rounded-full bg-teal-accent-400">
                {t("supportuppercase")}
              </p>
            </div>
            <h2 className="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-[#170F49] sm:text-4xl md:mx-auto">
              <span className="relative inline-block">
                <svg
                  viewBox="0 0 52 24"
                  fill="currentColor"
                  className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
                >
                  <defs>
                    <pattern
                      id="7e5e8ff8-1960-4094-a63a-2a0c0f922d69"
                      x="0"
                      y="0"
                      width=".135"
                      height=".30"
                    >
                      <circle cx="1" cy="1" r=".7" />
                    </pattern>
                  </defs>
                  <rect
                    fill="url(#7e5e8ff8-1960-4094-a63a-2a0c0f922d69)"
                    width="52"
                    height="24"
                  />
                </svg>
                <span className="relative">{t("supportspan")}</span>
              </span>{" "}
              {t("supporttitle")}
            </h2>
            <p className="text-base mb-20 text-gray-500 md:text-lg ">
              {t("supportdescription")}
            </p>
          </div>
        </div>
        <div className="max-w-screen-xl -mt-28  px-4 mx-auto sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-4 row-gap-8 lg:grid-cols-2">
            <div className="space-y-4 ">
              <div class="text-left ">
                <Item title={t("supportquestiontitle1")}>
                  <div className="ml-2">{t("supportquestionresponse1")}</div>
                </Item>
              </div>
              <div class="text-left ">
                <Item title={t("supportquestiontitle3")}>
                  <div className="ml-2">{t("supportquestionresponse3")}</div>
                </Item>
              </div>
              <div class="text-left ">
                <Item title={t("supportquestiontitle5")}>
                  <div className="ml-2">{t("supportquestionresponse5")}</div>
                </Item>
              </div>
            </div>
            <div className="space-y-4">
              <div class="text-left ">
                <Item title={t("supportquestiontitle2")}>
                  <div className="ml-2">{t("supportquestionresponse2")}</div>
                </Item>
              </div>
              <div class="text-left ">
                <Item title={t("supportquestiontitle4")}>
                  <div className="ml-2">{t("supportquestionresponse4")}</div>
                </Item>
              </div>
              <div class="text-left ">
                <Item title={t("supportquestiontitle6")}>
                  <div className="ml-2">{t("supportquestionresponse6")}</div>
                </Item>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ;
