import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Modal from "../components/Modal";
import { useTranslation } from "react-i18next";
import Contact from "../components/Contact";
import Pricing from "../components/Pricing";

const Loyalty = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <div className="">
        <div className="bg-[#FFE6DE]">
          <div className="px-4 relative mx-auto sm:max-w-xl md:max-w-full  lg:px-8 ">
            <div className="px-4  text-left  mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 content">
              <div className="grid gap-12 row-gap-8 lg:grid-cols-2">
                <div className="flex flex-col justify-center">
                  <div className="max-w-xl mb-6 mt-16 lg:mt-0 md:mt-24">
                    <p className="mb-5 text-xs font-semibold tracking-widest text-[#2E307C] uppercase title-font text-pirGray-100">
                      &mdash; {t("ecommerceuppercasemodule")}
                    </p>
                    <h1 class="text-4xl font-semibold sm:text-6xl space-y-3 text-[#2E307C]">
                      {t("ecommercetitlemodule")}
                    </h1>
                    <p class="mt-5 text-gray-600 leading-loose max-w-md">
                      {t("ecommercedescriptionmodule")}
                    </p>
                  </div>
                  <div className="flex space-x-4 mt-4">
                    <button
                      class=" inline-flex items-center px-8 py-3.5 overflow-hidden text-white bg-[#2E307C] rounded-full group active:bg-indigo-500 focus:outline-none focus:ring hover:bg-[#363996]"
                      onClick={() => {
                        setIsOpen(true);
                      }}
                    >
                      <span class=" font-medium ">
                        {t("ecommercebuttonmodule")}
                      </span>
                    </button>
                  </div>
                </div>

                <Carousel
                  className="z-10 relative ss"
                  autoPlay={true}
                  showThumbs={false}
                  showArrows={false}
                  infiniteLoop={true}
                  interval={6000}
                  swipeable={false}
                  showStatus={false}
                  showIndicators={false}
                  swipeAnimationHandler={false}
                  animationHandler="fade"
                  handleTransition="fade"
                >
                  <div>
                    <img
                      className="self-end"
                      src="https://img.invupos.com/clauweb/Products%20module%20/Products%20photo%201.png"
                      loading="lazy"
                      alt="Ecommerce Hero"
                    />
                  </div>
                  <div>
                    <img
                      className="self-end"
                      src="https://img.invupos.com/clauweb/Products%20module%20/Products%20photo%202.png"
                      loading="lazy"
                      alt="Ecommerce Hero"
                    />
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isOpen && <Modal setIsOpen={setIsOpen} />}

      <section>
        <div class="px-4 py-28 mx-auto max-w-screen-xl sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 gap-8 lg:gap-16 lg:grid-cols-2">
            <img
              alt="#"
              src="https://img.invupos.com/clauweb/Sections%20images%20/Sections1.webp"
              class="object-cover w-full h-full sm:h-full"
            />
            <div class="lg:py-28 text-left">
              <p className="inline-block  py-px mb-4 text-xs font-semibold tracking-wider text-[#EC392A] uppercase rounded-full bg-teal-accent-400">
                &mdash; {t("ecommerceuppercase")}
              </p>
              <h2 class="text-3xl font-bold  sm:text-5xl text-[#2E307C]">
                {t("ecommercetitle")}
              </h2>

              <p class="mt-4 text-gray-600">{t("ecommercedescription")}</p>

              <div class="flex space-x-4 mt-6">
                <button
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  class="w-full py-3 px-6 text-center  rounded-full transition duration-300 bg-[#EC392A]  active:bg-cyan-600 focus:bg-cyan-800 sm:w-max hover:bg-[#DE3E31]"
                >
                  <span class="block text-white text-sm font-semibold">
                    {t("ecommercebuttonone")}
                  </span>
                </button>
                {/* <button
                  type="button"
                  title="Start buying"
                  class="w-full py-3 px-6 text-center rounded-full transition border border-[#EC392A] sm:w-max hover:border-[#DE3E31]"
                >
                  <span class="block font-semibold text-[#EC392A] text-sm">
                    {t("ecommercebuttontwo")}
                  </span>
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-[#F3F4F6]">
        <div class="px-4 py-28 mx-auto max-w-screen-xl sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 gap-8 lg:gap-16 lg:grid-cols-2 ">
            <div className="relative  overflow-hidden  lg:order-last">
              <img
                alt="#"
                src="https://img.invupos.com/clauweb/Sections%20images%20/Loyaltysection.webp"
                class="object-cover  w-full h-full sm:h-full"
              />
            </div>
            <div class="lg:py-28 text-left">
              <p className="inline-block  py-px mb-4 text-xs font-semibold tracking-wider text-[#EC392A] uppercase rounded-full bg-teal-accent-400">
                &mdash; {t("loyaltyuppercase")}
              </p>
              <h2 class="text-3xl font-bold  sm:text-5xl text-[#2E307C]">
                {t("loyaltytitle")}
              </h2>

              <p class="mt-4 text-gray-600">{t("loyaltydescription")}</p>

              <div class="flex space-x-4 mt-6">
                <button
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  class="w-max py-3 px-6 text-center  rounded-full transition duration-300 bg-[#EC392A]  active:bg-cyan-600 focus:bg-cyan-800 hover:bg-[#DE3E31]"
                >
                  <span class="block text-white text-sm font-semibold">
                    {t("loyaltybuttonone")}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Pricing />
      <Contact />
    </>
  );
};

export default Loyalty;
