import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [company, setCompany] = useState("");
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleSubmit = (evt) => {
    evt.preventDefault();

    if (!captchaValue) {
      toast.error("Please complete the CAPTCHA");
      return;
    }

    var xhr = new XMLHttpRequest();
    var url =
      "https://api.hsforms.com/submissions/v3/integration/submit/3797225/e4a64690-1aac-4c62-8e32-8b56b009f4a3";

    //Split the name into first and last name
    var name = firstName.split(" ");
    var nombre = name[0];
    //check if the name has a last name
    var apellido = name.length > 1 ? name[1] : "";
    var data = {
      fields: [
        {
          name: "firstname",
          value: nombre,
        },
        {
          name: "lastname",
          value: apellido,
        },
        {
          name: "email",
          value: email,
        },

        {
          name: "mobilephone",
          value: phone,
        },
        {
          name: "company",
          value: company,
        },
        {
          name: "message",
          value: message,
        },
      ],
      captcha: {
        token: captchaValue,
        secret: '6LfEB_spAAAAAJiOqFXLEZqnTdhDlL5dCutQI6qK', 
      },
    };
    console.log(data);
    var final_data = JSON.stringify(data);

    xhr.open("POST", url);
    // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        toast.custom((t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
          >
            <div className="flex-1 w-0 p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0 pt-0.5">
                  <img
                    className="h-10 w-10 rounded-full"
                    src="https://img.invupos.com/clauweb/Contact%20/clau_contact.svg"
                    alt=""
                  />
                </div>
                <div className="ml-3 flex-1">
                  <p className="text-sm font-medium text-gray-900">Clau.io</p>
                  <p className="mt-1 text-sm text-gray-500">
                    <span className="font-semibold text-green-600">
                      Message sent.
                    </span>{" "}
                    We will communicate soon 📭
                  </p>
                </div>
              </div>
            </div>
            <div className="flex border-l border-gray-200">
              <button
                onClick={() => toast.dismiss(t.id)}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Close
              </button>
            </div>
          </div>
        ));
      } else if (xhr.readyState === 4 && xhr.status === 403) {
        alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
      } else if (xhr.readyState === 4 && xhr.status === 404) {
        alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found
      } else if (xhr.readyState === 4 && xhr.status === 400) {
        //TODO @YannickAlexander poner el toster bonito si responde un arreglo xhr.data.errors
        // alert(xhr.responseText);
        toast.custom((t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
          >
            <div className="flex-1 w-0 p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0 pt-0.5">
                  <img
                    className="h-10 w-10 rounded-full"
                    src="https://img.invupos.com/clauweb/Contact%20/clau_contact.svg"
                    alt=""
                  />
                </div>
                <div className="ml-3 flex-1">
                  <p className="text-sm font-medium text-gray-900">Invu POS</p>
                  <p className="mt-1 text-sm text-gray-500">
                    <span className="font-semibold text-red-600">Error</span>{" "}
                    Bad request ❌
                  </p>
                </div>
              </div>
            </div>
            <div className="flex border-l border-gray-200">
              <button
                onClick={() => toast.dismiss(t.id)}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Close
              </button>
            </div>
          </div>
        ));
      }
    };
    // Sends the request
    xhr.send(final_data);
    clearForm();
    // <Redirect to={`${prefix}` + "/thanks-for-sending-message"} />;
    // history.push(`${prefix}/thanks-for-sending-message`);
  };

  const clearForm = () => {
    setFirstName("");
    setEmail("");
    setPhone("");
    setMessage("");
    setCompany("");
    setCaptchaValue(null);
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };


  return (
    <>
      <section class="bg-[#F7F7FB]">
        <div class="px-4 py-16 mx-auto max-w-screen-xl sm:px-6 lg:px-8">
          <div class="grid grid-cols-1  gap-x-16 gap-y-8 lg:grid-cols-5">
            <div class="lg:py-12 text-left lg:col-span-2">
              <p className="inline-block  py-px mb-4 text-xs font-semibold tracking-wider text-[#EC392A] uppercase rounded-full bg-teal-accent-400">
                &mdash; {t("contactuppercase")}
              </p>
              <h2 className="text-[45px] font-bold leading-none tracking-tight text-[#2E307C]">
                {t("contacttitle")}
              </h2>
              <p class="max-w-xl text-gray-500 mt-6 text-lg">
                {t("contactdescription")}
              </p>

              <div class="flex items-start mt-10">
                <span class="flex-shrink-0 p-4 bg-[#FDEBEA] rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#EC392A"
                    class="w-6 h-6"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>

                <div class="ml-4">
                  <h2 class="text-lg font-bold text-[#170F49]">
                    {t("contactcelllabel")}
                  </h2>

                  <p class="mt-1 text-base text-gray-500">{t("contactcell")}</p>
                </div>
              </div>
              <div class="flex items-start mt-10">
                <span class="flex-shrink-0 p-4 bg-[#FDEBEA] rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#EC392A"
                    class="w-6 h-6"
                  >
                    <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                    <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
                  </svg>
                </span>

                <div class="ml-4">
                  <h2 class="text-lg font-bold text-[#170F49]">
                    {t("contactemaillabel")}
                  </h2>

                  <p class="mt-1 text-base text-gray-500">
                    {t("contactemail")}
                  </p>
                </div>
              </div>
              <div class="flex items-start mt-10">
                <span class="flex-shrink-0 p-4 bg-[#FDEBEA] rounded-full">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="#EC392A"
                    class="w-6 h-6"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>

                <div class="ml-4">
                  <h2 class="text-lg font-bold text-[#170F49]">
                    {t("contactlocationlabel")}
                  </h2>
                  <p class="mt-1 text-base text-gray-500">
                    {t("contactlocation")}
                  </p>
                </div>
              </div>
            </div>

            <div class="p-8 bg-white rounded-[34px] shadow-lg lg:p-12 lg:col-span-3">
              <form
                onSubmit={handleSubmit}
                class="space-y-8 items-left text-left"
              >
                <div class="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  <div>
                    <label for="email" class="text-sm font-medium ">
                      {t("namelabelcontact")}
                    </label>

                    <div class="relative mt-3">
                      <input
                        class="w-full p-4 pr-12 text-sm border border-gray-200 rounded-full shadow focus:outline-none focus:ring focus:ring-orange-600"
                        placeholder="John Carter"
                        required
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div>
                    <label for="email" class="text-sm font-medium">
                      {t("emaillabelcontact")}
                    </label>

                    <div class="relative mt-3">
                      <input
                        class="w-full p-4 pr-12 text-sm border border-gray-200 rounded-full shadow focus:outline-none focus:ring focus:ring-orange-600"
                        placeholder={t("emailinputcontact")}
                        required
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div class="grid grid-cols-1 gap-4  sm:grid-cols-2">
                  <div>
                    <label for="email" class="text-sm font-medium ">
                      {t("numberlabelcontact")}
                    </label>

                    <div class="relative mt-3">
                      <input
                        class="w-full p-4 pr-12 text-sm border border-gray-200 rounded-full shadow focus:outline-none focus:ring focus:ring-orange-600"
                        placeholder="(+507) 6207-6360"
                        required
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </div>

                  <div>
                    <label for="email" class="text-sm font-medium">
                      {t("companylabelcontact")}
                    </label>

                    <div class="relative mt-3">
                      <input
                        class="w-full p-4 pr-12 text-sm border border-gray-200 rounded-full shadow focus:outline-none focus:ring focus:ring-orange-600"
                        placeholder={t("companyinputcontact")}
                        required
                        type="text"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <label for="email" class="text-sm font-medium">
                    {t("feedbacklabelcontact")}
                  </label>
                  <textarea
                    class="w-full p-3 mt-3 text-sm border border-gray-200 rounded-[20px] shadow focus:outline-none focus:ring focus:ring-orange-600"
                    placeholder={t("feedbackinputcontact")}
                    required
                    rows={8}
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
                <div className="mt-4">
                <ReCAPTCHA
                  sitekey="6LfEB_spAAAAAPrhAHwiDM8Mjap6TxNKkL3xvYbL"
                  onChange={handleCaptchaChange}
                />
                </div>
                <div class="mt-4">
                  <button
                    type="submit"
                    value="Submit"
                    class="relative inline-flex items-center px-8 py-4 overflow-hidden text-white bg-[#EC392A]  rounded-full group active:bg-indigo-500 focus:outline-none focus:ring  shadow-lg shadow-[#F49189]"
                  >
                    <span class="absolute right-0 transition-transform translate-x-full group-hover:-translate-x-4">
                      <svg
                        class="w-5 h-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </svg>
                    </span>

                    <span class="text-sm font-medium transition-all group-hover:mr-4">
                      {t("buttoncontact")}
                    </span>
                  </button>
                </div>
              </form>
              <Toaster position="bottom-center" reverseOrder={false} />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
