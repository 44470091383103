import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "./Modal";
import { Link } from "react-router-dom";
import useLocalePrefix from "../components/utils/useLocalePrefix";

const Section_one = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const prefix = useLocalePrefix();
  return (
    <>
      <section>
        <div class="px-4 py-28 mx-auto max-w-screen-xl sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 gap-8 lg:gap-16 lg:grid-cols-2">
            <img
              alt="E-commerce"
              src="https://img.invupos.com/clauweb/Sections%20images%20/Ecommerce.webp"
              class="object-cover w-full h-full sm:h-full"
            />
            <div class="lg:py-28 text-left">
              <p className="inline-block  py-px mb-4 text-xs font-semibold tracking-wider text-[#EC392A] uppercase rounded-full bg-teal-accent-400">
                &mdash; {t("ecommerceuppercase")}
              </p>
              <h2 class="text-3xl font-bold  sm:text-5xl text-[#2E307C]">
                {t("ecommercetitle")}
              </h2>

              <p class="mt-4 text-gray-600">{t("ecommercedescription")}</p>

              <div class="flex space-x-4 mt-6">
                <button
                  type="button"
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  class="w-full py-3 px-6 text-center  rounded-full transition duration-300 bg-[#EC392A]  active:bg-cyan-600 focus:bg-cyan-800 sm:w-max hover:bg-[#DE3E31]"
                >
                  <span class="block text-white text-sm font-semibold">
                    {t("ecommercebuttonone")}
                  </span>
                </button>
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  to={`${prefix}` + "/ecommerce"}
                  class="w-full py-3 px-6 text-center rounded-full transition border border-[#EC392A] sm:w-max hover:border-[#DE3E31]"
                >
                  <span class="block font-semibold text-[#EC392A] text-sm">
                    {t("ecommercebuttontwo")}
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {isOpen && <Modal setIsOpen={setIsOpen} />}
    </>
  );
};

export default Section_one;
