import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "./Modal";

const Section_two = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <section className="bg-[#F3F4F6]">
        <div class="px-4 py-28 mx-auto max-w-screen-xl sm:px-6 lg:px-8">
          <div class="grid grid-cols-1 gap-8 lg:gap-16 lg:grid-cols-2 ">
            <div className="relative  overflow-hidden  lg:order-last">
              <img
                alt="Loyalty program"
                src="https://img.invupos.com/clauweb/Sections%20images%20/Loyalty.webp"
                class="object-cover  w-full h-full sm:h-full"
              />
            </div>
            <div class="lg:py-28 text-left">
              <p className="inline-block  py-px mb-4 text-xs font-semibold tracking-wider text-[#EC392A] uppercase rounded-full bg-teal-accent-400">
                &mdash; {t("loyaltyuppercase")}
              </p>
              <h2 class="text-3xl font-bold  sm:text-5xl text-[#2E307C]">
                {t("loyaltytitle")}
              </h2>

              <p class="mt-4 text-gray-600">{t("loyaltydescription")}</p>

              <div class="flex space-x-4 mt-6">
                <button
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  class="w-max py-3 px-6 text-center  rounded-full transition duration-300 bg-[#EC392A]  active:bg-cyan-600 focus:bg-cyan-800 hover:bg-[#DE3E31]"
                >
                  <span class="block text-white text-sm font-semibold">
                    {t("loyaltybuttonone")}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      {isOpen && <Modal setIsOpen={setIsOpen} />}
    </>
  );
};

export default Section_two;
