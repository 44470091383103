import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Modal from "./Modal";
import { Link, useLocation } from "react-router-dom";
import useLocalePrefix from "../components/utils/useLocalePrefix";
import i18n from "i18next";

import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "./utils";
import { slice } from "lodash";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [navBg, setNavBg] = React.useState("red");
  const [navButtonColor, setNavButtonColor] = React.useState("red");

  const { t, i18n } = useTranslation();

  const location = useLocation();
  const prefix = useLocalePrefix();

  return (
    <>
      <Popover className="relative z-50 bg-[#FFE6DE] ">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 ">
          <div className="flex items-center justify-between  py-6 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1 ">
              <Link to="/">
                <span className="sr-only">Workflow</span>
                <img
                  className="h-8 w-auto sm:h-7 block sm:hidden lg:block"
                  src="https://img.invupos.com/clauweb/Navbar/Industries/logoclau.svg"
                  alt=""
                />
                <img
                  className="h-8 w-auto sm:h-7 hidden sm:block md:block lg:hidden"
                  src="https://img.invupos.com/clauweb/Navbar/Industries/logotypeclau.svg"
                  alt=""
                />
              </Link>
            </div>
            <div className="-my-2 -mr-2 md:hidden ">
              <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <span className="sr-only">Open menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <Popover.Group as="nav" className="hidden space-x-10 md:flex  ">
              <Popover className="relative ">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? "text-gray-900" : "text-gray-500",
                        "group  inline-flex items-center rounded-md  text-base font-medium hover:text-gray-900 focus:outline-none "
                      )}
                    >
                      <span>{t("categoryone")}</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? "text-gray-600" : "text-gray-400",
                          "ml-2 h-5 w-5 group-hover:text-gray-500"
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-20 -ml-4 mt-8 w-screen   lg:max-w-4xl md:max-w-xl transform px-2 sm:px-0 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1  ring-black ring-opacity-5">
                          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2 md:grid-cols-2">
                            {i18n
                              .t("solutions", { returnObjects: true })
                              .map((item, index) => (
                                <Link
                                  key={item.name}
                                  onClick={() => window.scrollTo(0, 0)}
                                  to={`${prefix}` + item.href}
                                >
                                  <Popover.Button
                                    disabled={index != 0}
                                    className="-m-3 flex items-start group text-left rounded-lg p-3 hover:bg-gray-100"
                                  >
                                    <div
                                      className="h-13 w-13 bg-[#2E307C] p-2 mx-auto flex text-center rounded-full flex-shrink-0 text-white group-hover:bg-[#ec392a] group-hover:transition  group-hover:text-white"
                                      aria-hidden="true"
                                    >
                                      <img
                                        className="w-8 h-8 p-1"
                                        src={item.icon}
                                      />
                                    </div>
                                    <div className="ml-4">
                                      <p className="text-base font-medium text-gray-900">
                                        {item.name}
                                      </p>
                                      <p className="mt-1 text-sm text-gray-500">
                                        {item.description}
                                      </p>
                                    </div>
                                  </Popover.Button>
                                </Link>
                              ))}
                          </div>
                          {/* <div className="space-y-6 bg-gray-50 px-5 py-5 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                            {i18n
                              .t("callsToAction", { returnObjects: true })
                              .map((item) => (
                                <div key={item.name} className="flow-root">
                                  <a
                                    href={item.href}
                                    className="-m-3 flex items-center rounded-md p-3 text-base font-medium text-gray-900 hover:bg-gray-100"
                                  >
                                    <img
                                      className="h-6 w-6 flex-shrink-0 text-gray-400"
                                      aria-hidden="true"
                                      src={item.icon}
                                    />
                                    <span className="ml-3">{item.name}</span>
                                  </a>
                                </div>
                              ))}
                          </div> */}
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>

              <Popover className="relative ">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? "text-gray-900" : "text-gray-500",
                        "group inline-flex items-center rounded-md  text-base font-medium hover:text-gray-900 focus:outline-none "
                      )}
                    >
                      <span>{t("categorytwo")}</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? "text-gray-600" : "text-gray-400",
                          "ml-2 h-5 w-5 group-hover:text-gray-500"
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-20 left-1/2 z-10 mt-8 w-screen max-w-md -translate-x-1/2 transform px-2 sm:px-0 ">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                          <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 ">
                            {i18n
                              .t("products", { returnObjects: true })
                              .map((item, index) => (
                                <Link
                                  key={item.name}
                                  onClick={() => window.scrollTo(0, 0)}
                                  to={`${prefix}` + item.href}
                                >
                                  <Popover.Button
                                    disabled={index != 0}
                                    className="-m-3 flex items-start group text-left rounded-lg p-3  hover:bg-gray-100"
                                  >
                                    {/* <img
                                      className="h-11 w-11 bg-[#2E307C] rounded-full p-1.5 flex-shrink-0 text-white text-white group-hover:bg-[#ec392a] group-hover:transition   group-hover:text-white"
                                      aria-hidden="true"
                                      src={item.icon}
                                    /> */}
                                    <div
                                      className="h-13 w-13 bg-[#2E307C] p-2 mx-auto flex text-center rounded-full flex-shrink-0 text-white group-hover:bg-[#ec392a] group-hover:transition  group-hover:text-white"
                                      aria-hidden="true"
                                    >
                                      <img
                                        className="w-8 h-8 p-1"
                                        src={item.icon}
                                      />
                                    </div>
                                    <div className="ml-4">
                                      <p className="text-base font-medium text-gray-900">
                                        {item.name}
                                      </p>
                                      <p className="mt-1 text-sm text-gray-500">
                                        {item.description}
                                      </p>
                                    </div>
                                  </Popover.Button>
                                </Link>
                              ))}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>

              <Link
                onClick={() => {
                  setIsOpen(true);
                }}
                to={`${prefix}` + "/"}
                // to={`${prefix}` + "/partnership"}

                className="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                {t("categoryfour")}
              </Link>
              {/* <Link
                to={`${prefix}` + "/blog"}
                className="text-base font-medium text-gray-500 hover:text-gray-900"
              >
                Blog
              </Link> */}
            </Popover.Group>
            <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">
              <div className="hidden md:block mt-.5 md:flex lg:ml-8 lg:flex space-x-3 mr-12">
                <Link to={genLanguagePath("", location)} as={Link}>
                  <img
                    onClick={() => changeLanguage("es")}
                    src="https://img.invupos.com/clauweb/Navbar/Idioms/Flag%20es.png"
                    alt=""
                    className="w-8 h-8 rounded-full border-2 hover:border-[#EC392A]"
                  />
                </Link>
                <Link to={genLanguagePath("en", location)} as={Link}>
                  <img
                    onClick={() => changeLanguage("en")}
                    src="https://img.invupos.com/clauweb/Navbar/Idioms/flag-en.webp"
                    alt=""
                    className="w-8 h-8 rounded-full border-2  hover:border-[#EC392A] "
                  />
                </Link>
              </div>

              <button
                class="relative inline-flex items-center bg-[#2E307C] px-8 py-3.5 overflow-hidden text-white  rounded-full group active:bg-indigo-500 focus:outline-none focus:ring"
                onClick={() => {
                  setIsOpen(true);
                }}
              >
                <span class="absolute right-0 transition-transform translate-x-full group-hover:-translate-x-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
                    />
                  </svg>
                </span>

                <span class=" font-medium transition-all group-hover:mr-4">
                  {t("buttonnavbar")}
                </span>
              </button>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute inset-x-0 top-0 origin-top-right transform p-2 transition md:hidden"
          >
            <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="px-5 pt-5 pb-6">
                <div className="flex items-center justify-between">
                  <div>
                    <img
                      className="h-6 w-auto"
                      src="https://img.invupos.com/clauweb/Navbar/Industries/logoclau.svg"
                      alt="Workflow"
                    />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-400">
                      <span className="sr-only">Close menu</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-8">
                    {i18n
                      .t("solutions", { returnObjects: true })
                      .map((item, index) => (
                        <Link
                          key={item.name}
                          onClick={() => window.scrollTo(0, 0)}
                          to={`${prefix}` + item.href}
                        >
                          <Popover.Button
                            disabled={index != 0}
                            className="-m-3 flex items-center rounded-md p-2 hover:bg-gray-50"
                          >
                            <div className="bg-[#EC392A] p-2 rounded-full">
                              <img
                                className="h-6 w-6 flex-shrink-0 text-white"
                                aria-hidden="true"
                                src={item.icon}
                              />
                            </div>
                            <span className="ml-3 text-base font-medium text-gray-900">
                              {item.name}
                            </span>
                          </Popover.Button>
                        </Link>
                      ))}
                  </nav>
                </div>
              </div>

              <div className="space-y-6 py-6 px-5 ">
                <div className="grid grid-cols-2 g">
                  <Link
                    onClick={() => {
                      setIsOpen(true);
                    }}
                    to={`${prefix}` + "/"}
                    // to={`${prefix}` + "/partnership"}
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >
                    {t("categoryfour")}
                  </Link>
                  {/* <Link
                    to={`${prefix}` + "/blog"}

                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >
                    <Popover.Button>Blog</Popover.Button>
                  </Link> */}
                </div>

                <div className="grid grid-cols-2 text-left gap-y-4 gap-x-8">
                  {/* <a
                    href="#"
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >
                    Pricing
                  </a>

                  <a
                    href="#"
                    className="text-base font-medium text-gray-900 hover:text-gray-700"
                  >
                    Customers
                  </a> */}
                  <span className="tracking-widest text-gray-500 text-sm uppercase title-font text-semibold ">
                    All resources
                  </span>
                  <br></br>

                  {i18n
                    .t("products", { returnObjects: true })
                    .map((item, index) => (
                      <div>
                        <Link
                          key={item.name}
                          onClick={() => window.scrollTo(0, 0)}
                          to={`${prefix}` + item.href}
                        >
                          <Popover.Button
                            disabled={index != 0}
                            className="text-left"
                          >
                            <div class="rounded-lg sm:p-6 ">
                              <span class="inline-block rounded-full bg-[#2E307C] p-2 text-white">
                                <img
                                  className="flex-shrink-0 text-white"
                                  aria-hidden="true"
                                  src={item.icon}
                                />
                              </span>

                              <div>
                                <h3 class="text-base  font-medium text-gray-900">
                                  {item.name}
                                </h3>
                              </div>
                            </div>
                          </Popover.Button>
                        </Link>
                      </div>
                    ))}
                </div>

                <div>
                  <span className="tracking-widest text-gray-500 text-sm uppercase title-font text-semibold ">
                    Languages
                  </span>
                  <br></br>

                  <div className="flex lg:ml-8 mt-4 mb-6 lg:flex space-x-3 mr-12">
                    <Link to={genLanguagePath("", location)} as={Link}>
                      <img
                        onClick={() => changeLanguage("es")}
                        src="https://img.invupos.com/clauweb/Navbar/Idioms/Flag%20es.png"
                        alt=""
                        className="w-6 h-6 rounded-full border-2  hover:border-indigo-600"
                      />
                    </Link>
                    <Link to={genLanguagePath("en", location)} as={Link}>
                      <img
                        onClick={() => changeLanguage("en")}
                        src="https://img.invupos.com/clauweb/Navbar/Idioms/flag-en.webp"
                        alt=""
                        className="w-6 h-6 rounded-full border-2  hover:border-indigo-600 "
                      />
                    </Link>
                  </div>

                  <button
                    href="#"
                    className="flex w-full items-center justify-center rounded-full border border-transparent bg-[#EC392A] px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700"
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    Get demo
                  </button>
                  {/* <p className="mt-6 text-center text-base font-medium text-gray-500">
                    Existing customer?{" "}
                    <a
                      href="#"
                      className="text-indigo-600 hover:text-indigo-500"
                    >
                      Sign in
                    </a>
                  </p> */}
                </div>
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
      {isOpen && <Modal setIsOpen={setIsOpen} />}
    </>
  );
}

export default withRouter(Navbar);

function genLanguagePath(locale, location) {
  const lang = i18n.language;

  if (isHomePage(location.pathname)) {
    return `/${locale}`;
  }

  if (locale === "") {
    if (lang === "es") {
      return location.pathname;
    }

    return location.pathname.replace(`/${lang}`, "");
  }

  if (lang === "es") {
    return `/${locale}${location.pathname}`;
  }

  return location.pathname.replace(`/${lang}`, `/${locale}`);
}

function isHomePage(pathname) {
  const lang = i18n.language;
  if (pathname.replace(lang, "") === "/") {
    return true;
  }
  return false;
}
