import React from "react";
import Contact from "../components/Contact";
import FAQ from "../components/FAQ";
import Features from "../components/Features";
import Hero from "../components/Hero";
import Pricing from "../components/Pricing";
import Results from "../components/Results";
import Section_one from "../components/Section_one";
import Section_two from "../components/Section_two";

const Landing = () => {
  return (
    <>
      <Hero />
      <Section_one />
      <Section_two />
      <Features />
      <Pricing />
      <Results />
      <FAQ />
      <Contact />
    </>
  );
};

export default Landing;
