import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";
import ReCAPTCHA from "react-google-recaptcha";

const Modal = ({ setIsOpen }) => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [company, setCompany] = useState("");
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleSubmit = (evt) => {
    evt.preventDefault();

    if (!captchaValue) {
      toast.error("Please complete the CAPTCHA");
      return;
    }

    var xhr = new XMLHttpRequest();
    var url =
      "https://api.hsforms.com/submissions/v3/integration/submit/3797225/e4a64690-1aac-4c62-8e32-8b56b009f4a3";
    //Split the name into first and last name
    var name = firstName.split(" ");
    var nombre = name[0];
    //check if the name has a last name
    var apellido = name.length > 1 ? name[1] : "";
    var data = {
      fields: [
        {
          name: "firstname",
          value: nombre,
        },
        {
          name: "lastname",
          value: apellido,
        },
        {
          name: "email",
          value: email,
        },

        {
          name: "mobilephone",
          value: phone,
        },
        {
          name: "company",
          value: company,
        },
        {
          name: "message",
          value: message,
        },
      ],
      captcha: {
        token: captchaValue,
        secret: '6LfEB_spAAAAAJiOqFXLEZqnTdhDlL5dCutQI6qK', 
      },
    };
    console.log(data);
    var final_data = JSON.stringify(data);

    xhr.open("POST", url);
    // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
    xhr.setRequestHeader("Content-Type", "application/json");

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        toast.custom((t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
          >
            <div className="flex-1 w-0 p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0 pt-0.5">
                  <img
                    className="h-10 w-10 rounded-full"
                    src="https://img.invupos.com/clauweb/Contact%20/clau_contact.svg"
                    alt=""
                  />
                </div>
                <div className="ml-3 flex-1">
                  <p className="text-sm font-medium text-gray-900">Clau.io</p>
                  <p className="mt-1 text-sm text-gray-500">
                    <span className="font-semibold text-green-600">
                      Message sent.
                    </span>{" "}
                    We will communicate soon 📭
                  </p>
                </div>
              </div>
            </div>
            <div className="flex border-l border-gray-200">
              <button
                onClick={() => toast.dismiss(t.id)}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Close
              </button>
            </div>
          </div>
        ));
      } else if (xhr.readyState === 4 && xhr.status === 403) {
        alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.
      } else if (xhr.readyState === 4 && xhr.status === 404) {
        alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found
      } else if (xhr.readyState === 4 && xhr.status === 400) {
        toast.custom((t) => (
          <div
            className={`${
              t.visible ? "animate-enter" : "animate-leave"
            } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
          >
            <div className="flex-1 w-0 p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0 pt-0.5">
                  <img
                    className="h-10 w-10 rounded-full"
                    src="https://img.invupos.com/clauweb/Contact%20/clau_contact.svg"
                    alt=""
                  />
                </div>
                <div className="ml-3 flex-1">
                  <p className="text-sm font-medium text-gray-900">Invu POS</p>
                  <p className="mt-1 text-sm text-gray-500">
                    <span className="font-semibold text-red-600">Error</span>{" "}
                    Bad request ❌
                  </p>
                </div>
              </div>
            </div>
            <div className="flex border-l border-gray-200">
              <button
                onClick={() => toast.dismiss(t.id)}
                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                Close
              </button>
            </div>
          </div>
        ));
      }
    };
    xhr.send(final_data);
    clearForm();
  };

  const clearForm = () => {
    setFirstName("");
    setEmail("");
    setPhone("");
    setMessage("");
    setCompany("");
    setCaptchaValue(null);
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };


  return (
    <>
      <div class="flex justify-center z-50 inset-0 fixed h-screen items-center  antialiased bg-gray-500 bg-opacity-75 transition-opacity ">
        <div class="flex flex-col w-11/12 overflow-auto max-w-7xl mx-auto  rounded-lg border border-gray-300 shadow-xl modal-dialog">
          <div class="relative  rounded-lg shadow-2xl lg:pb-0 ">
            <div class="p-5 ml-auto   bg-white text-left lg:w-2/4 sm:p-12 modal-body">
              <div class="flex flex-row  justify-between mb-12 rounded-tl-lg rounded-tr-lg">
                <p class="text-sm font-semibold tracking-widest uppercase">
                  {t("contactuppercase")}
                </p>
                <button onClick={() => setIsOpen(false)}>
                  <svg
                    class="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </button>
              </div>

              <form
                onSubmit={handleSubmit}
                class="space-y-8 items-left text-left"
              >
                <div class="grid grid-cols-2 gap-4 sm:grid-cols-2">
                  <div>
                    <label for="email" class="text-sm font-medium">
                      {t("namelabelcontact")}
                    </label>

                    <div class="relative mt-3">
                      <input
                        class="w-full p-4 pr-12 text-sm border border-gray-200 rounded-full shadow focus:outline-none focus:ring focus:ring-orange-600"
                        placeholder="John Carter"
                        required
                        type="text"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                  </div>

                  <div>
                    <label for="email" class="text-sm font-medium">
                      {t("emaillabelcontact")}
                    </label>

                    <div class="relative mt-3">
                      <input
                        class="w-full p-4 pr-12 text-sm border border-gray-200 rounded-full shadow focus:outline-none focus:ring focus:ring-orange-600"
                        placeholder={t("emailinputcontact")}
                        required
                        type="text"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div class="grid grid-cols-2 gap-4  sm:grid-cols-2">
                  <div>
                    <label for="email" class="text-sm font-medium ">
                      {t("numberlabelcontact")}
                    </label>

                    <div class="relative mt-3">
                      <input
                        class="w-full p-4 pr-12 text-sm border border-gray-200 rounded-full shadow focus:outline-none focus:ring focus:ring-orange-600"
                        placeholder="(+507) 6207-6360"
                        required
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </div>

                  <div>
                    <label for="email" class="text-sm font-medium">
                      {t("companylabelcontact")}
                    </label>

                    <div class="relative mt-3">
                      <input
                        class="w-full p-4 pr-12 text-sm border border-gray-200 rounded-full shadow focus:outline-none focus:ring focus:ring-orange-600"
                        placeholder={t("companyinputcontact")}
                        required
                        type="text"
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <label for="email" class="text-sm font-medium">
                    {t("feedbacklabelcontact")}
                  </label>
                  <textarea
                    class="w-full p-3 mt-3 text-sm border border-gray-200 rounded-[20px] shadow focus:outline-none focus:ring focus:ring-orange-600"
                    placeholder={t("feedbackinputcontact")}
                    required
                    rows={3}
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                </div>
                <div className="mt-4">
                <ReCAPTCHA
                  sitekey="6LfEB_spAAAAAPrhAHwiDM8Mjap6TxNKkL3xvYbL"
                  onChange={handleCaptchaChange}
                />
                </div>
                <div class="mt-4">
                  <button
                    class="relative inline-flex items-center px-8 py-4 overflow-hidden text-white bg-[#EC392A] rounded-full group active:bg-indigo-500 focus:outline-none focus:ring  shadow-lg shadow-[#F49189]"
                    type="submit"
                    value="Submit"
                  >
                    <span class="absolute right-0 transition-transform translate-x-full group-hover:-translate-x-4">
                      <svg
                        class="w-5 h-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </svg>
                    </span>

                    <span class="text-sm font-medium transition-all group-hover:mr-4">
                      {t("buttoncontact")}
                    </span>
                  </button>
                </div>
              </form>
              <Toaster position="bottom-center" reverseOrder={false} />
            </div>

            <div class="absolute bottom-0 left-0 w-full lg:block hidden  lg:h-full lg:w-1/2">
              <img
                alt="Contact Clau.io"
                src="https://img.invupos.com/clauweb/Modal%20Contact/Modal%20image.webp"
                class="absolute inset-0 object-cover w-full h-full lg:block hidden"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;

