import React, { useState } from "react";

import { Link } from "react-router-dom";

const articles = [
  {
    id: 1,
    name: "Sistemas Punto De Venta POS Para Tiendas en Panamá",
    description:
      "Un sistema POS para tiendas, no solamente es parte fundamental dentro del funcionamiento de una tienda exitosa hoy en día. Si no, que es la mejor alternativa para que los dueños de los negocios puedan llevar a cabo sus operaciones financieras",
    date: "July 11, 2022",
    views: "2.3k",
    comments: "27",
    href: "/blog/sistemas-punto-de-venta-para-tiendas-en-panama",
    imageSrc:
      "https://img.invupos.com/webinvu/Blog%20invu/Blog%201/Cover%20blog%201.jpg",
    imageAlt: "",
  },
  {
    id: 2,
    name: "¿Cómo recibir pagos en línea en Venezuela?",
    description:
      "Con el paso del tiempo, los métodos de pago han experimentado muchos cambios en Venezuela hasta el punto que debes preguntarte cómo recibir pagos en línea. Desde un panorama de únicamente efectivo a una serie de alternativas de pago, que muestran diferentes beneficios a sus usuarios por implementarlas en distintos ciclos de venta. ",
    date: "July 18, 2022",
    views: "2.3k",
    comments: "27",
    href: "/blog/como-recibir-pagos-en-linea-en-venezuela",
    imageSrc:
      "https://img.invupos.com/webinvu/Blog%20invu/Blog%202/Cover%20blog%202.jpg",
    imageAlt: "",
  },
  {
    id: 3,
    name: "Firma electrónica en Venezuela",
    description:
      "La firma electrónica en Venezuela es una herramienta que permite la comunicación y la ejecución de negocios entre las personas. Igualmente, en las estructuras de comercio, lo que les facilita a las personas elaborar contratos por medio de canales electrónicos. ",
    date: "July 19, 2022",
    views: "2.3k",
    comments: "27",
    href: "/blog/firma-electronica-en-venezuela",
    imageSrc:
      "https://img.invupos.com/webinvu/Blog%20invu/Blog%203/Cover%20blog3.jpg",
    imageAlt: "",
  },
  {
    id: 4,
    name: "Mejor programa de facturación online",
    description:
      "Debido a la obligación legal de contar con un software de facturación online en los negocios, estos programas han pasado a ser una herramienta indispensable para cualquier emprendedor. Este tipo de programas te permite, de manera sencilla, mantener la contabilidad al día y tener un control sobre la facturación",
    date: "January 18, 2022",
    views: "2.3k",
    comments: "27",
    href: "/blog/mejor-programa-de-facturacion-online",
    imageSrc:
      "https://img.invupos.com/webinvu/Blog%20invu/Blog%208/Clover%20blog%208.avif",
    imageAlt: "",
  },
  {
    id: 5,
    name: "Invu POS, el mejor procesador de pagos online",
    description:
      "Es probable que estés buscando información relacionada con un procesador de pagos online o monedero electrónico. Debes saber que un procesador de pagos online sirve para múltiples cosas, ya sea para realizar transferencias en línea, hacer compras en línea o cobrar tu dinero por Internet. ",
    date: "July 25, 2022",
    views: "2.3k",
    comments: "27",
    href: "/blog/invu-pos-el-mejor-procesador-de-pago-online",
    imageSrc:
      "https://img.invupos.com/webinvu/Blog%20invu/Blog%203/Cover%20blog%203.avif",
    imageAlt: "",
  },
  {
    id: 6,
    name: "Software POS para restaurantes en Venezuela",
    description:
      "Un sistema confiable POS para restaurantes en Venezuela marca la diferencia entre un negocio lento, con procesos financieros desorganizados, y uno con clientes satisfechos, con facilidad de gestión desde un smartphone. ",
    date: "July 25, 2022",
    views: "2.3k",
    comments: "27",
    href: "/blog/software-pos-para-restaurantes-en-venezuela",
    imageSrc:
      "https://img.invupos.com/webinvu/Blog%20invu/Blog%204/Cover%20blog%204.jpg",
    imageAlt: "",
  },
  {
    id: 7,
    name: "E-Commerce con Invu POS",
    description:
      "Para cualquier empresa autónoma o E-commerce dedicada a la venta, la gestión de los procesos de facturación puede ser una tarea difícil. Puesto que, implica papeleo, quita tiempo y significa muchos dolores de cabeza que puedes evitar si integras tu E-Commerce con Invu POS",
    date: "July 25, 2022",
    views: "2.3k",
    comments: "27",
    href: "/blog/e-commerce-con-invu-pos",
    imageSrc:
      "https://img.invupos.com/webinvu/Blog%20invu/Blog%205/Cover%20blog%205.jpg",
    imageAlt: "",
  },
  {
    id: 8,
    name: "Loyalty con Invu POS",
    description:
      "Integrar un sistema Loyalty con Invu POS es un paso importante para cualquier negocio, ya que mejora la manera de hacer los pagos y brinda mayor control sobre las ventas. En general, es sinónimo de simplicidad, optimización del flujo de la información y efectividad operacional. Por lo tanto, conoce más detalles sobre las ventajas que puede ofrecerte la integración de software Loyalty con Invu POS.",
    date: "July 29, 2022",
    views: "2.3k",
    comments: "27",
    href: "/blog/loyalty-con-invu-pos",
    imageSrc:
      "https://img.invupos.com/webinvu/Blog%20invu/Blog%206/Cover%20blog%206.jpg",
    imageAlt: "",
  },
  {
    id: 9,
    name: "Cómo evitar perder materia prima en los inventarios",
    description:
      "Compras desmedidas de materias primas y componentes que provocan exceso de stock,; falta de inmediatez en el flujo de información; grandes diferencias entre el flujo de entradas y de salidas",
    date: "July 04, 2022",
    views: "2.3k",
    comments: "27",
    href: "/blog/como-evitar-perder-materia-prima-en-los-inventarios",
    imageSrc:
      "https://img.invupos.com/webinvu/Blog%20invu/Blog%209/Cover%20blog%209.jpeg",
    imageAlt: "",
  },
  {
    id: 10,
    name: "4 funciones del sistema de Invu POS que ayudan a mejorar el control de ventas",
    description:
      "Un mundo en el que un empresario disfruta de la comodidad del hogar. Tal comodidad implica, entre otras cosas, poder coger el Smartphone, la Tablet o el ordenador y poder revisar inventario, catálogo de clientes y ventas realizadas.",
    date: "July 05, 2022",
    views: "2.3k",
    comments: "27",
    href: "/blog/cuatro-funciones-del-sistema-invupos-para-mejorar-control-de-ventas",
    imageSrc:
      "https://img.invupos.com/webinvu/Blog%20invu/Blog%2010/Cover%20blog%2010.jpeg",
    imageAlt: "",
  },
  {
    id: 11,
    name: "Consejos para diseñar la página web de un restaurante",
    description:
      "Está claro, como no podía ser de otro modo, que los conocimientos del propietario de un restaurante siempre van a estar más relacionados con el mundo culinario que con el del diseño, y la programación, de páginas web.",
    date: "July 06, 2022",
    views: "2.3k",
    comments: "27",
    href: "/blog/consejos-para-diseñar-la-pagina-web-de-un-restaurante",
    imageSrc:
      "https://img.invupos.com/webinvu/Blog%20invu/Blog%2011/Cover%20blog%2011.jpeg",
    imageAlt: "",
  },
  {
    id: 12,
    name: "Qué es el cash flow y cómo mejorarlo",
    description:
      "Ya sea el dueño de un negocio o el alto directivo de una empresa, cualquier responsable empresarial debería ser capaz de poder leer, y analizar, un balance financiero. Además, estas personas deberían de estar preparadas para actuar en consecuencia.",
    date: "July 07, 2022",
    views: "2.3k",
    comments: "27",
    href: "/blog/que-es-el-cash-flow-y-como-mejorarlo",
    imageSrc:
      "https://img.invupos.com/webinvu/Blog%20invu/Blog%2012/Cover%20blog%2012%20.png",
    imageAlt: "",
  },
  {
    id: 13,
    name: "Ventajas de unificar, con el Sistema POS, las ventas on line y off line de un negocio",
    description:
      "Es evidente: tener tienda on line es, a día de hoy, fundamental para fomentar el éxito de un negocio off line. No en vano, el mercado del comercio electrónico es enorme. A principios de 2021, las ventas de comercio electrónico alcanzaron los $4.891 mil millones. ",
    date: "July 08, 2022",
    views: "2.3k",
    comments: "27",
    href: "/blog/ventajas-de-unificar-con-el-sistema-pos",
    imageSrc:
      "https://img.invupos.com/webinvu/Blog%20invu/Blog%2013/Cover%20blog%2013.png",
    imageAlt: "",
  },
  {
    id: 14,
    name: "Seis funciones indispensables en el punto de venta para controlar las sucursales",
    description:
      "Siempre sucede. Cuantas más unidades de negocio desarrolla una empresa, menor visibilidad se obtiene de cada una de ellas. Para evitar que esto suceda, para tener un control efectivo de las diferentes sucursales de una compañía, una empresa debe poder utilizar herramientas capaces de monitorizar con éxito todas las operaciones que se realicen dentro del conjunto. ",
    date: "July 08, 2022",
    views: "2.3k",
    comments: "27",
    href: "/blog/seis-funciones-indispensables-en-el-punto-de-venta-para-controlar-las-sucursales",
    imageSrc:
      "https://img.invupos.com/webinvu/Blog%20invu/Blog%2014/Cover%20blog%2014.png",
    imageAlt: "",
  },
  {
    id: 15,
    name: "Invu POS, una solución de comercio electrónico personalizadas",
    description:
      "Personalizar las plataformas de comercio electrónico es una opción cada vez más extendida, sobre todo entre los negocios minoristas B2B que comercializan sus productos y servicios en línea. Con cada vez más, compradores B2B esperan, de los negocios en los que gastan su dinero, una experiencia de compra similar a la que reciben en sus adquisiciones B2C. ",
    date: "August 01, 2022",
    views: "2.3k",
    comments: "27",
    href: "/blog/solucion-de-comercio-electronico-personalizada",
    imageSrc:
      "https://img.invupos.com/webinvu/Blog%20invu/Blog%2015/Cover%20blog%2015.jpeg",
    imageAlt: "",
  },
  {
    id: 16,
    name: "Los datos de su punto de venta POS son una mina de oro",
    description:
      "Reducción de errores de captura, ahorro de tiempo a los clientes, mejoras en el control de inventarios, simplificación de los métodos de contabilidad… Hoy en día, los sistemas de punto de venta (POS) han revolucionado la forma en la que se gestionan los datos de clientes, hasta convertirlos en una herramienta que genera altas rentabilidades y ahorro a las empresas. ",
    date: "August 05, 2022",
    views: "2.3k",
    comments: "27",
    href: "/blog/los-datos-de-su-punto-de-venta-son-una-mina-de-oro",
    imageSrc:
      "https://img.invupos.com/webinvu/Blog%20invu/Blog%2016/Cover%20blog%2016.jpeg",
    imageAlt: "",
  },
  {
    id: 17,
    name: "Siete habilidades esenciales para las operaciones y la gestión de restaurantes",
    description:
      "Cualquier dueño de un restaurante, cafetería o bar sabe que tener capacidad de liderazgo es una de las habilidades esenciales para intentar asegurar el éxito de un negocio de comida. A partir de aquí, equilibrar los números en los libros de contabilidad, contratar al personal adecuado o consolidar la fidelidad de la clientela son también elementos esenciales en la personalidad, y habilidades, de la persona que debe operar y administrar un negocio de restaurante.",
    date: "August 08, 2022",
    views: "2.3k",
    comments: "27",
    href: "/blog/siete-habilidades-esenciales-para-la-gestion-de-restaurantes",
    imageSrc:
      "https://img.invupos.com/webinvu/Blog%20invu/Blog%2017/Cover%20blog%2017.jpeg",
    imageAlt: "",
  },
  {
    id: 18,
    name: "4 beneficios de los pedidos en línea para propietarios de restaurantes",
    description:
      "Los pedidos en línea para restaurantes recibieron un fuerte impulso durante el confinamiento. Posteriormente, y durante los meses transcurridos de 2022, esta modalidad de consumir comida se ha consolidado para este tipo de negocio.",
    date: "August 12, 2022",
    views: "2.3k",
    comments: "27",
    href: "/blog/cuatro-beneficios-de-los-pedidos-en-linea-para-propietarios-de-restaurantes",
    imageSrc:
      "https://img.invupos.com/webinvu/Blog%20invu/Blog%2018/Cover%20blog%2018.jpeg",
    imageAlt: "",
  },
  {
    id: 19,
    name: "Cómo utilizar el video marketing para promocionar su restaurante",
    description:
      "El video marketing es una herramienta de lo más eficaz para promocionar cualquier negocio en general… Y con los restaurantes funciona a la perfección.  Y es que una imagen impacta, y es capaz de transmitir contenidos en un segundo y, además, no requiere prácticamente ni de atención ni de esfuerzo mental por parte del espectador. ",
    date: "August 15, 2022",
    views: "2.3k",
    comments: "27",
    href: "/blog/como-utilizar-el-video-marketing-para-promocionar-su-restaurante",
    imageSrc:
      "https://img.invupos.com/webinvu/Blog%20invu/Blog%2019/Cover%20blog%2019.jpeg",
    imageAlt: "",
  },
  {
    id: 20,
    name: "10 industrias donde los programas de lealtad son extremadamente efectivos",
    description:
      "Restaurantes de comida rápida y cafeterías; salones de belleza y cosmética; gimnasios y comercios especializados en supplements & vitamins; negocios relacionados con productos de farmacia; viajes y turismo; electrónica y gaming; cines y salas de teatro; tiendas de suministros para mascotas y empresas de productos agrícolas; supermercados y minimarkets; consultorios médicos y clínicas. ",
    date: "August 19, 2022",
    views: "2.3k",
    comments: "27",
    href: "/blog/diez-industrias-donde-los-programas-de-lealtad-son-extremadamente-efectivos",
    imageSrc:
      "https://img.invupos.com/webinvu/Blog%20invu/Blog%2020/Cover%20blog%2020.avif",
    imageAlt: "",
  },
  // {
  //   id: 9,
  //   name: "Cómo proteger ganancias en restaurantes con tecnología de costos de inventario y alimentos",
  //   description:
  //     "Antes de la aparición del COVID-19, los comercios de alimentos empezaron a enfocar sus esfuerzos en aumentar las ventas y la rentabilidad usando software de gestión de inventario.",
  //   date: "August 08, 2022",
  //   views: "2.3k",
  //   comments: "27",
  //   href: "/blog/como-proteger-ganancias-en-restaurantes-con-tecnologia",
  //   imageSrc:
  //     "https://res.cloudinary.com/dslkvd23s/image/upload/v1659115267/Branding%20Invu%20/Blogs%20/Blog%2009/M2---Art-1_hty0z2.jpg",
  //   imageAlt: "",
  // },
  // {
  //   id: 10,
  //   name: "Importancia de usar el software POS con sistema de lealtad",
  //   description:
  //     "Sin importar el sector de la industria donde te encuentres, siempre vas a tener que librar una batalla por fidelizar clientes y la mejor forma es combinar el Software POS con el programa de lealtad. ",
  //   date: "August 05, 2022",
  //   views: "2.3k",
  //   comments: "27",
  //   href: "/blog/importancia-de-usar-el-software-pos-con-sistema-de-lealtad",
  //   imageSrc:
  //     "https://res.cloudinary.com/dslkvd23s/image/upload/v1659129994/Branding%20Invu%20/Blogs%20/Blog%2010/M2---Art-2_imxapx.jpg",
  //   imageAlt: "",
  // },
  // {
  //   id: 11,
  //   name: "Sistema POS para el comercio electrónico en línea y fuera de línea",
  //   description:
  //     "Contar con un sistema POS eCommerce es importante para garantizar el éxito de un emprendimiento hoy más que nunca. No solamente porque necesitas un canal online para poder satisfacer la creciente.",
  //   date: "August 08, 2022",
  //   views: "2.3k",
  //   comments: "27",
  //   href: "/blog/sistema-pos-para-el-comercio-electronico-en-línea-y-fuera-de-linea",
  //   imageSrc:
  //     "https://res.cloudinary.com/dslkvd23s/image/upload/v1659367533/Branding%20Invu%20/Blogs%20/Blog%2011/M2---Art-3_wxawhy.jpg",
  //   imageAlt: "",
  // },
];

const Blog = () => {
  return (
    <>
      <section class="px-4 py-24 mx-auto max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
        <div class="w-full mx-auto text-left md:w-11/12 xl:w-9/12 md:text-center">
          <h2 className="mb-3 text-xs font-semibold tracking-widest text-black uppercase title-font text-pirGray-100">
            our blog
          </h2>
          <h1 class="mb-6 text-4xl font-extrabold leading-none tracking-normal text-gray-900 md:text-5xl md:tracking-tight">
            Explore{" "}
            <span class="block w-full lg:inline">
              articles and contributions
            </span>{" "}
            for our community
          </h1>
        </div>
        <form
          action=""
          method="post"
          id="revue-form"
          name="revue-form"
          target="_blank"
          class="
                  p-2
                  mt-16
                  transition
                  duration-500
                  ease-in-out
                  transform
                  border2
                  bg-gray-50
                  md:mx-auto
                  rounded-xl
                  sm:max-w-3xl
                  lg:flex
                "
        >
          <div class=" lg:flex space-y 4">
            <div class="flex-1 min-w-0 revue-form-group">
              <select
                id="name"
                type="text"
                class="
                        block
                        w-full
                        px-5
                        py-3
                        text-base text-neutral-600
                        placeholder-gray-300
                        transition
                        duration-500
                        ease-in-out
                        transform
                        bg-transparent
                        border border-transparent
                        rounded-md
                        focus:outline-none
                        focus:border-transparent
                        focus:ring-2
                        focus:ring-white
                        focus:ring-offset-2
                        focus:ring-offset-gray-300
                      "
              >
                <option value="1">All Topics</option>
                <option value="2">Finances</option>
                <option value="3">Payments</option>
                <option value="4">Marketing</option>
                <option value="5">Customers</option>
                <option value="6">Management</option>
              </select>
            </div>
            <div class="flex-1 min-w-0 revue-form-group">
              <select
                id="name"
                type="text"
                class="
                        block
                        w-full
                        px-5
                        py-3
                        text-base text-neutral-600
                        placeholder-gray-300
                        transition
                        duration-500
                        ease-in-out
                        transform
                        bg-transparent
                        border border-transparent
                        rounded-md
                        focus:outline-none
                        focus:border-transparent
                        focus:ring-2
                        focus:ring-white
                        focus:ring-offset-2
                        focus:ring-offset-gray-300
                      "
              >
                <option value="1">All business</option>
                <option value="2">Home Repair</option>
                <option value="3">Health & beauty</option>
                <option value="4">Other</option>
                <option value="5">Retail</option>
                <option value="6">Food & Beverage</option>
              </select>
            </div>
            <div class="flex-1 min-w-0 revue-form-group">
              <select
                id="name"
                type="text"
                class="
                        block
                        w-full
                        px-5
                        py-3
                        text-base text-neutral-600
                        placeholder-gray-300
                        transition
                        duration-500
                        ease-in-out
                        transform
                        bg-transparent
                        border border-transparent
                        rounded-md
                        focus:outline-none
                        focus:border-transparent
                        focus:ring-2
                        focus:ring-white
                        focus:ring-offset-2
                        focus:ring-offset-gray-300
                      "
              >
                <option value="1">All content</option>
                <option value="2">Articles</option>
                <option value="3">Guides</option>
                <option value="4">Research</option>
                <option value="5">Podcast</option>
                <option value="6">Tools</option>
              </select>
            </div>
          </div>
          <div class="mt-4 sm:mt-0 lg:ml-3 revue-form-actions">
            <button
              // type="submit"
              disabled
              value="Subscribe"
              name="member[subscribe]"
              id="member_submit"
              class="
                      block
                      w-full
                      px-5
                      py-3
                      text-base
                      text-white
                      bg-indigo-600
                      border border-transparent
                      rounded-lg
                      shadow
                      font-semibold
                      hover:bg-indigo-700
                      focus:outline-none
                      focus:ring-2
                      focus:ring-white
                      focus:ring-offset-2
                      focus:ring-offset-gray-300
                      sm:px-10
                    "
            >
              Search Filter
            </button>
          </div>
        </form>
        <div class="grid grid-cols-1 mt-24 gap-12 md:grid-cols-2 lg:grid-cols-2 ">
          <div>
            <Link onClick={() => window.scrollTo(0, 0)} class="block">
              <img
                class="object-cover w-full h-82 shadow-xl rounded-xl"
                // src="https://img.invupos.com/webinvu/Blog%20invu/Blog%2021/Cover%20blog%2021%20.jpeg"
                src="https://img.invupos.com/clauweb/Blog/placeholder-image.png"
                alt=""
              />

              <div class="mt-10">
                <div class="h-full flex items-start">
                  <div class="w-12 flex-shrink-0 flex flex-col text-center leading-none">
                    <span class="text-gray-500 pb-2 mb-2 border-b-2 border-gray-200">
                      Jul
                    </span>
                    <span class="font-medium text-lg text-gray-800 title-font leading-none">
                      07
                    </span>
                  </div>
                  <div class="flex-grow pl-6">
                    <h2 class="tracking-widest text-xs title-font font-medium text-indigo-500 mb-1 uppercase">
                      Destacado
                    </h2>
                    <h1 class="title-font max-w-md text-xl font-semibold text-gray-900 mb-3">
                      Software de contabilidad y facturación en la nube
                    </h1>
                    <p class="mt-2 max-w-lg text-gray-600 ">
                      A día de hoy, y gracias al avance de la tecnología, los
                      softwares de contabilidad en la nube se han convertido en
                      muy valiosas herramientas de trabajo para los
                      departamentos de contabilidad de las empresas.
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div>
            <Link class="block" onClick={() => window.scrollTo(0, 0)}>
              <img
                class="object-cover w-full h-82 shadow-xl rounded-xl"
                src="https://img.invupos.com/clauweb/Blog/placeholder-image.png"
                // src="https://img.invupos.com/webinvu/Blog%20invu/Blog%2022/Cover%20blog%2022%20.jpeg"
                alt=""
              />

              <div class="mt-10">
                <div class="h-full flex items-start">
                  <div class="w-12 flex-shrink-0 flex flex-col text-center leading-none">
                    <span class="text-gray-500 pb-2 mb-2 border-b-2 border-gray-200">
                      Jul
                    </span>
                    <span class="font-medium text-lg text-gray-800 title-font leading-none">
                      08
                    </span>
                  </div>
                  <div class="flex-grow pl-6">
                    <h2 class="tracking-widest text-xs title-font font-medium text-indigo-500 mb-1">
                      DESTACADO
                    </h2>
                    <h1 class="title-font max-w-md text-xl font-semibold text-gray-900 mb-3">
                      Facturación electrónica en Panamá: requisitos y novedades
                    </h1>
                    <p class="mt-2 max-w-lg text-gray-600 ">
                      En Panamá, la factura electrónica es un modelo de
                      facturación que, además de reemplazar el uso de impresoras
                      fiscales, acelera la transformación digital de las
                      empresas e impulsa el ingreso de los emprendedores en la
                      economía formal.
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </section>
      <section className="bg-gray-100">
        <div className="px-4 py-24 mx-auto max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:px-8">
          <h1 className="-mt-8 text-4xl font-bold xl:block hidden leading-tight text-gray-800">
            Business articles
          </h1>
          <span class="block w-24 h-2 mt-1 xl:block hidden  bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange "></span>

          <h1 className="-mt-8 text-3xl mb-4 font-bold xl:hidden block leading-tight lg:leading-10 text-gray-800">
            Business articles
          </h1>
          <span class="block w-24 h-2 mt-1 lg:invisible  bg-gradient-to-r from-pirLightBlue to-pirPurple hover:from-pirPink hover:to-pirOrange "></span>
          <div className="grid gap-6  grid-cols-1 mt-8 grid grid-cols-1 gap-12 md:grid-cols-2  lg:grid-cols-2 xl:grid-cols-3">
            {articles.map((article) => (
              <Link onClick={() => window.scrollTo(0, 0)}>
                <div className="overflow-hidden transition-shadow duration-300 bg-white rounded">
                  <a aria-label="Article">
                    <img
                      src="https://img.invupos.com/clauweb/Blog/placeholder-image.png"
                      // src={article.imageSrc}
                      className="object-cover w-full h-64 rounded-t-lg"
                      alt=""
                    />
                  </a>
                  <div className="py-5 p-6">
                    <p className="mb-2 text-xs font-semibold text-gray-600 uppercase">
                      {article.date}
                    </p>
                    <a
                      href="/resources/blog/article-one"
                      aria-label="Article"
                      className="inline-block mb-3 text-black transition-colors duration-200 hover:text-deep-purple-accent-700"
                    >
                      <p className="text-2xl font-bold leading-7">
                        {article.name.length > 48
                          ? `${article.name.substring(0, 48)}...`
                          : article.name}
                      </p>
                    </a>
                    <p className="mb-4 text-gray-700">
                      {article.description.length > 240
                        ? `${article.description.substring(0, 238)}...`
                        : article.description}
                    </p>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
