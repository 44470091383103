import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Slider from "react-slick";
import Modal from "./Modal";

//translate
import { useTranslation } from "react-i18next";

const Hero = () => {
  const { t, i18n } = useTranslation();
  const [language, setLenguage] = useState("es");
  const [isOpen, setIsOpen] = useState(false);

  const onChangeLanguaje = () => {
    i18n.changeLanguage(language);
    if (language === "es") {
      setLenguage("en");
    } else {
      setLenguage("es");
    }
  };

  var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 20000,
    slidesToShow: 6,
    slidesToScroll: 4,
    initialSlide: 0,
    cssEase: "linear",
    className: "wrapper",
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,

          // dots: true,
        },
      },
      {
        breakpoint: 812,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <div className="">
        <div className="bg-[#FFE6DE]">
          <div className="px-4 relative mx-auto sm:max-w-xl md:max-w-full  lg:px-8 ">
            <div className="px-4  text-left  mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 content">
              <div className="grid gap-12 row-gap-8 lg:grid-cols-2">
                <div className="flex flex-col justify-center">
                  <div className="max-w-xl mb-6 mt-16 lg:mt-0 md:mt-24">
                    <p className="mb-5 lg:text-xs text-[11.5px] font-semibold tracking-widest text-[#2E307C] uppercase title-font text-pirGray-100">
                      &mdash; {t("herouppercase")}
                    </p>
                    <h1 class="text-4xl font-semibold sm:text-6xl space-y-3 text-[#2E307C]">
                      {t("herotitle")}
                    </h1>
                    <p class="mt-5 text-gray-600 leading-loose max-w-lg">
                      {t("herodescription")}
                    </p>
                  </div>
                  {/* <form action="" class="max-w-lg ">
                    <div class="relative flex items-center p-1 rounded-full bg-white shadow-md md:p-2">
                      <input
                        placeholder={t("inputhero")}
                        class="w-full p-4 rounded-full placeholder-gray-600 outline-none appearance-none bg-transparent border-none text-gray-600"
                        type="email"
                        required
                      />
                      <button
                        type="submit"
                        title="Start buying"
                        class="ml-auto py-3 px-4 rounded-full text-center bg-[#EC392A] md:px-8 hover:bg-[#DE3E31]"
                      >
                        <span class="hidden w-max text-white font-semibold md:block ">
                          {t("buttonhero")}
                        </span>

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="h-6 w-6 mx-auto text-white md:hidden"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                          />
                        </svg>
                      </button>
                    </div>
                  </form> */}
                  <div>
                    <button
                      class="relative inline-flex items-center bg-[#EC392A] hover:bg-[#DE3E31] px-8 py-3.5 overflow-hidden text-white rounded-full group "
                      onClick={() => {
                        setIsOpen(true);
                      }}
                    >
                      <span class=" font-medium"> {t("buttonhero")}</span>
                    </button>
                  </div>

                  <div className="flex mt-3 items-center max-w-lg p-2 max-w-md  ">
                    <div className="mr-2">
                      <svg
                        className="w-8 h-8 transition-colors duration-200 text-deep-purple-accent-400 group-hover:text-white sm:w-8 sm:h-8"
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="#25A55C"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                    <p className="text-gray-500 text-xs transition-colors duration-200 group-hover:text-indigo-500">
                      {t("herotext")}
                    </p>
                  </div>
                </div>

                <Carousel
                  className="z-10 relative ss"
                  autoPlay={true}
                  showThumbs={false}
                  showArrows={false}
                  infiniteLoop={true}
                  interval={6000}
                  swipeable={false}
                  showStatus={false}
                  showIndicators={false}
                  swipeAnimationHandler={false}
                  animationHandler="fade"
                  handleTransition="fade"
                >
                  <div>
                    <img
                      className="self-end"
                      src="https://img.invupos.com/clauweb/Hero/images/Heroclau_photo1.webp"
                      alt="Ecommerce Hero"
                      loading="lazy"
                    />
                  </div>
                  <div>
                    <img
                      className="self-end "
                      src="https://img.invupos.com/clauweb/Hero/images/Heroclau_photo2.webp"
                      alt="Ecommerce Hero"
                      loading="lazy"
                    />
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
        </div>

        <div className="relative px-4 sm:px-0 ">
          <div className="absolute inset-0 bg-[#FFE6DE] h-1/2 " />
          <div className="relative grid mx-auto overflow-hidden  bg-white divide-y rounded-full shadow sm:divide-y-0 sm:divide-x sm:max-w-screen-sm  lg:max-w-screen-xl">
            <div className="wrapper p-6 mt-3">
              <Slider {...settings}>
                <div className="mt-2">
                  <img
                    class="h-9 sm:h-10 md:h-9 mx-auto lg:h-10"
                    src="https://img.invupos.com/clauweb/Partners/logos/cinnabon-1.webp"
                    alt="Cinnabon"
                    loading="lazy"
                  />
                </div>
                <div className="mt-1 lg:ml-4 sm:ml-2 md:ml-2 ml-2">
                  <img
                    class="h-9 sm:h-10 md:h-10 mx-auto lg:h-11 "
                    src="https://img.invupos.com/clauweb/Partners/logos/Sushiexpress1.webp"
                    alt="Sushi Express"
                    loading="lazy"
                  />
                </div>
                <div className="mt-2.5 lg:ml-6 sm:ml-6 md:ml-6 ml-6">
                  <img
                    class="h-7 sm:h-8 md:h-9 mx-auto lg:h-[35px]"
                    src="https://img.invupos.com/clauweb/Partners/logos/Elcosto.webp"
                    alt="El Costo"
                    loading="lazy"
                  />
                </div>
                <div className="mt-2 sm:ml-6 md:ml-5 lg:ml-6 ml-3">
                  <img
                    class="h-7 sm:h-8 md:h-9 mx-auto lg:h-9 ml-9"
                    src="https://img.invupos.com/clauweb/Partners/logos/Grupotova.webp"
                    alt="Grupo Tova"
                    loading="lazy"
                  />
                </div>
                <div className="mt-1.5 lg:ml-4 sm:ml-4 md:ml-4 ml-3">
                  <img
                    class="h-9 sm:h-10 md:h-10 mx-auto lg:h-[42px]"
                    src="https://img.invupos.com/clauweb/Partners/logos/CredicorpBank_logo.webp"
                    alt="Credicorp Bank"
                    loading="lazy"
                  />
                </div>
                <div className="mt-1.5 lg:ml-7 sm:ml-8 md:ml-8 ml-8">
                  <img
                    class="h-10 sm:h-10 md:h-11 mx-auto lg:h-12"
                    src="https://img.invupos.com/clauweb/Partners/Tsugoi.webp"
                    alt="Tsugoi"
                    loading="lazy"
                  />
                </div>
                <div className="mt-.5 lg:ml-6 sm:ml-6 md:ml-6 ml-6">
                  <img
                    class="h-12 sm:h-[53px] md:h-[53px] mx-auto lg:h-[58px]"
                    src="https://img.invupos.com/clauweb/Partners/logos/FDCenter_logo.webp"
                    alt="FDCenter"
                    loading="lazy"
                  />
                </div>
                <div className="mt-2.5 lg:ml-2 sm:ml-2 md:ml-2 ml-2">
                  <img
                    class="h-7 sm:h-9 md:h-9 mx-auto lg:h-[38px]"
                    src="https://img.invupos.com/clauweb/Partners/logos/Bravapizza_logo.webp"
                    alt="Brava Pizza"
                    loading="lazy"
                  />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
      {isOpen && <Modal setIsOpen={setIsOpen} />}
    </>
  );
};

export default Hero;
